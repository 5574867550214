import { HTTPError, NormalizedOptions } from 'ky';

export class CustomHttpError extends HTTPError {
	constructor(response: Response, request: Request, options: NormalizedOptions) {
		super(response, request, options);

		// When wondering, WTF?
		// https://github.com/microsoft/TypeScript/issues/13965
		// https://stackoverflow.com/a/51229776
		Object.setPrototypeOf(this, CustomHttpError.prototype);
		Error.captureStackTrace(this, CustomHttpError);
		this.name = 'CustomHttpError';
	}
}
