import type {
	TimeframeData,
	TimeframesTypes,
	DateTimeProperties,
} from '@modules/timeframes/types/timeframes.types';
import type { Actor } from '@modules/actors/types/actors.types';
import type { CommitmentWithIncludes } from '@modules/commitments/types/commitments.types';
import { Organization } from '@modules/organizations/types/organizations.types';
import { CreateCommitmentDto } from '@modules/commitments/types/commitments.types';
import { SupportedTimezone } from '@infrastructure/datetime/types/datetime.types';

export enum BoardView {
	BOARD = 'board',
	AGENDA = 'agenda',
}

export enum BoardType {
	PERSONAL = 'personal',
	SHARED = 'shared',
}

export interface Board {
	id: number | string;
	name: string;
	view: BoardView;
	resolution: TimeframesTypes;
	type: BoardType;
	firstColumn: 'periodStart' | 'previousTimeframe' | 'currentTimeframe';
	defaultColumnsNumber: number;
	timezone: SupportedTimezone;
	exclude: DateTimeProperties;
	actorIds: Actor['id'][];
	canEdit?: { result: boolean; reason: string };
}

export type BoardSettings = Pick<
	Board,
	'view' | 'resolution' | 'firstColumn' | 'defaultColumnsNumber' | 'exclude'
>;

export interface BoardWithIncludes extends Omit<Board, 'actors'> {
	actors: Actor[];
	organization: Organization;
}

export interface BoardTimeframe {
	from: Date;
	to: Date;
	signature: number;
}

export interface BoardColumn extends BoardTimeframe {
	title: string;
	isCurrent: boolean;
}

export interface BoardCommitmentsMap {
	[timeframeSignature: TimeframeData['signature']]: {
		timeframeData: TimeframeData;
		actorsCommitments: { [actorId: Actor['id']]: CommitmentWithIncludes['id'][] };
	};
}

export interface CreateBoardFormData {
	name: string;
	view: BoardView;
	resolution: TimeframesTypes;
	exclude: Record<any, any>;
	type: BoardType;
	timezone: string;
	defaultColumnsNumber?: number;
}

export interface UpdateBoardFormData extends Partial<CreateBoardFormData> {}

export const ROUTE_PARAM_TRIGGER_EDIT = 'boardEditActors';
