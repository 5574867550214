import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { TimeframesService } from '@modules/timeframes/services/timeframes.service';
import { ITimeframesStore, useTimeframesStore } from '@modules/timeframes/stores/timeframes.store';

export const TimeframesStoreSymbol: unique symbol = Symbol('Timeframes Store');
export const TimeframesServiceSymbol: unique symbol = Symbol('Timeframes Service');

export const timeframesModule = {
	[TimeframesServiceSymbol]: asClass(TimeframesService),
	[TimeframesStoreSymbol]: asFunction(() => useTimeframesStore()).singleton(),
};

export function useTimeframesModule() {
	const container = useDependenciesContainer();
	return {
		timeframesService: container.resolve(TimeframesServiceSymbol) as TimeframesService,
		timeframesStore: container.resolve(TimeframesStoreSymbol) as ITimeframesStore,
	};
}
