import { WebsocketsServiceSymbol } from '@infrastructure/websockets/websockets.module';
import { WebsocketsService } from '@infrastructure/websockets/services/websockets.service';
import { Organization } from '../types/organizations.types';

export class OrganizationsWebsocketsService {
	private readonly websocketService;
	constructor(dependencies: { [WebsocketsServiceSymbol]: WebsocketsService }) {
		this.websocketService = dependencies[WebsocketsServiceSymbol];
	}

	leaveAllOrganizationsRooms() {
		this.websocketService.emit('leaveAllOrganizationsRooms', null);
	}

	joinOrganizationWebsocketRoom(orgId: Organization['id']) {
		this.websocketService.emit('joinOrganizationRoom', { orgId });
	}
}
