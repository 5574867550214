import { type EventBusKey, useEventBus } from '@vueuse/core';
import { PendingInvitation } from '@modules/users/types/users.types';

export const OrganizationInvitationAcceptedKey: EventBusKey<{
	name: 'organization_invitation_accepted';
}> = Symbol('organization_invitation_accepted');

export interface OrganizationInvitationAcceptedPayload {
	invitation: PendingInvitation;
}

export const useOrganizationInvitationAccepted = useEventBus<
	typeof OrganizationInvitationAcceptedKey,
	OrganizationInvitationAcceptedPayload
>(OrganizationInvitationAcceptedKey);
