import { useEventBus, type EventBusKey } from '@vueuse/core';
import { CommitmentWithIncludes } from '../types/commitments.types';

export const CommitmentCreatedEventKey: EventBusKey<{ name: 'commitment_created' }> =
	Symbol('commitment_created');

export interface CommitmentCreatedEventPayload {
	commitment: CommitmentWithIncludes;
}

export const useCommitmentCreatedEvent = useEventBus<
	typeof CommitmentCreatedEventKey,
	CommitmentCreatedEventPayload
>(CommitmentCreatedEventKey);
