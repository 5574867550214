import { CustomError } from '@common/errors/CustomError';

export class PersonalOrganizationNotFound extends CustomError {
	constructor() {
		super(`Personal organization is missing.`);
	}
}

export class CurrentOrganizationMissing extends CustomError {
	constructor() {
		super(`Current organization is not set.`);
	}
}
