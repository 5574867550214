import { defineStore } from 'pinia';

import {
	CreateOrganizationRequest,
	Organization,
	OrganizationType,
} from '../types/organizations.types';

export const ORGANIZATIONS_STORE_NAME = 'organizations';
const NEW_ORG_FORM_INITIAL = { name: '', slug: '', invitees: [] };

export const useOrganizationsStore = defineStore(ORGANIZATIONS_STORE_NAME, {
	state: () => ({
		organizations: [] as Organization[],
		currentOrganization: undefined as Organization | undefined,
		newOrgForm: { ...NEW_ORG_FORM_INITIAL } as CreateOrganizationRequest,
		autoAcceptInvitationId: null as string | null,
	}),
	getters: {
		isCurrentOrgPersonal(state) {
			return () => {
				return state.currentOrganization?.type === OrganizationType.PERSONAL;
			};
		},
	},
	actions: {
		setCurrentOrganization(organization: Organization | undefined): void {
			this.currentOrganization = organization;
		},
		resetNewOrgForm() {
			this.newOrgForm = { ...NEW_ORG_FORM_INITIAL };
		},
		setAutoAcceptInvitationId(invitationId: string | null = null) {
			this.autoAcceptInvitationId = invitationId;
		},
	},
});

export type IOrganizationStore = ReturnType<typeof useOrganizationsStore>;
