import { DateTime, Interval } from 'luxon';
import { getLocalTimezone } from '@utils/datetime';

import {
	IDateTimeService as IDatetimeService,
	Duration,
	DateTimeUnit,
	DateTimeFormatOptions,
	DateTimeProperties,
} from '@modules/timeframes/types/timeframes.types';
import { SupportedTimezone } from '../types/datetime.types';

const FORMAT_SHORT_WITH_WEEKDAY: DateTimeFormatOptions = {
	weekday: 'short',
	month: 'short',
	day: 'numeric',
};

const FORMAT_SHORT_WITH_WEEKDAY_AND_TIME: DateTimeFormatOptions = {
	weekday: 'short',
	month: 'short',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
};

const FORMAT_TIME: DateTimeFormatOptions = {
	hour: 'numeric',
	minute: 'numeric',
};

const FORMAT_FULL_DATE: DateTimeFormatOptions = {
	day: 'numeric',
	month: 'short',
	year: 'numeric',
};

export class DatetimeService implements IDatetimeService {
	startOf(date: Date, unit: DateTimeUnit, timezone: SupportedTimezone) {
		return DateTime.fromJSDate(date).setZone(timezone).startOf(unit).toJSDate();
	}
	endOf(date: Date, unit: DateTimeUnit, timezone: SupportedTimezone) {
		return DateTime.fromJSDate(date).setZone(timezone).endOf(unit).toJSDate();
	}
	plus(date: Date, duration: Duration, timezone: SupportedTimezone): Date {
		return DateTime.fromJSDate(date).setZone(timezone).plus(duration).toJSDate();
	}
	minus(date: Date, duration: Duration, timezone: SupportedTimezone): Date {
		return DateTime.fromJSDate(date).setZone(timezone).minus(duration).toJSDate();
	}
	intervalLength(from: Date, to: Date, unit: DateTimeUnit, timezone: SupportedTimezone): number {
		return Interval.fromDateTimes(
			DateTime.fromJSDate(from).setZone(timezone),
			DateTime.fromJSDate(to).setZone(timezone),
		).length(unit);
	}
	fromTime(time: number, timezone: SupportedTimezone) {
		return DateTime.fromMillis(time).setZone(timezone).toISO();
	}
	toString(date: Date, format: DateTimeFormatOptions, timezone: SupportedTimezone): string {
		// TODO: Change en-US to a user/organization preference
		return DateTime.fromJSDate(date).setZone(timezone).setLocale('en-uk').toLocaleString(format);
	}
	formatShortWithWeekday(date: Date, timezone: SupportedTimezone): string {
		return this.toString(date, FORMAT_SHORT_WITH_WEEKDAY, timezone);
	}
	formatShortWithWeekdayAndTime(date: Date, timezone: SupportedTimezone): string {
		return this.toString(date, FORMAT_SHORT_WITH_WEEKDAY_AND_TIME, timezone);
	}
	formatTime(date: Date, timezone: SupportedTimezone): string {
		return this.toString(date, FORMAT_TIME, timezone);
	}
	formatFullDate(date: Date, timezone: SupportedTimezone): string {
		return this.toString(date, FORMAT_FULL_DATE, timezone);
	}
	hasAnyProperties(
		date: Date,
		properties: DateTimeProperties,
		timezone: SupportedTimezone,
	): boolean {
		if (!properties || typeof properties !== 'object') {
			throw new Error('Incorrect type of the properties argument.');
		}

		if (Object.keys(properties).length === 0) {
			return false;
		}

		const dateTime = DateTime.fromJSDate(date).setZone(timezone);

		for (const property in properties) {
			if (!(property in dateTime)) {
				throw new Error(`Invalid DateTime property ${property}.`);
			}

			if (
				Object.prototype.hasOwnProperty.call(properties, property) &&
				properties[property] instanceof Array &&
				properties[property]!.includes(Number(dateTime[property as keyof DateTime]))
			) {
				return true;
			}
		}

		return false;
	}
}
