import { alertsModule } from './alerts/alerts.module';
import { datetimeModule } from './datetime/datetime.module';
import { httpModule } from './http/http.module';
import { jsonModule } from './json/json.module';
import { loggerModule } from './logger/logger.module';
import { websocketsModule } from './websockets/websockets.module';

export const infrastructure = {
	...alertsModule,
	...datetimeModule,
	...httpModule,
	...jsonModule,
	...loggerModule,
	...websocketsModule,
};
