import type { Actor } from '@modules/actors/types/actors.types';
import type { Board, BoardWithIncludes } from '@modules/boards/types/boards.types';

export const CONTEXT_TYPE_BOARD = 'board';

export enum CommitmentStatus {
	TODO = 'todo',
	DONE = 'done',
	NOT_DONE = 'not_done',
	WARNING = 'warning',
}

export enum CommitmentView {
	FULL = 'full',
	COMPACT = 'compact',
}

export interface Commitment {
	id: number;
	description: string;
	boardId: BoardWithIncludes['id'];
	dueDate: string; // TODO: Change to Date in the final shape
	rank: string;
	status: CommitmentStatus;
	link?: string | null;
	notes?: string;
}

export interface CommitmentWithIncludes extends Commitment {
	actors: Actor[];
	board: BoardWithIncludes;
}

export interface CommitmentContext {
	type: 'board' | 'agenda';
	data: BoardWithIncludes;
}

export type CommitmentsFilterOptions = {
	ids?: CommitmentWithIncludes['id'][];
	description?: string;
	boardId?: BoardWithIncludes['id'];
	from?: Date;
	to?: Date;
};

export type CommitmentsSearchParams = {
	ids?: string;
	description?: string;
	boardId?: string;
	from?: string;
	to?: string;
};

export interface CreateCommitmentDto extends Omit<Commitment, 'id' | 'rank' | 'status'> {
	actorIds: Actor['id'][];
}

export interface UpsertCommitmentRequestDto extends Partial<CreateCommitmentDto> {
	newRankData?: CommitmentNewRankRequestDto;
	status?: CommitmentStatus;
}

export interface CommitmentNewRankRequestDto {
	previousCommitment?: CommitmentWithIncludes['id'];
	nextCommitment?: CommitmentWithIncludes['id'];
}
