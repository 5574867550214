import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { OrganizationsApiAdapter } from '@modules/organizations/api/organizations.api';
import { OrganizationsService } from '@modules/organizations/services/organizations.service';
import { OrganizationsWebsocketsService } from '@modules/organizations/services/organizations-websockets.service';
import {
	IOrganizationStore,
	useOrganizationsStore,
} from '@modules/organizations/stores/organizations.store';
import { OrganizationsInvitationsService } from './services/organizations-invitations.service';
import { OrganizationsInvitationsApiAdapter } from './api/organizations-invitations.api';

export const OrganizationsStoreSymbol: unique symbol = Symbol('Organizations Store');
export const OrganizationsApiAdapterSymbol: unique symbol = Symbol('Organizations API Adapter');
export const OrganizationsServiceSymbol: unique symbol = Symbol('Organizations Service');
export const OrganizationsWebsocketsServiceSymbol: unique symbol = Symbol(
	'Organizations Websockets Service',
);
export const OrganizationsInvitationsServiceSymbol: unique symbol = Symbol(
	'Organizations Invitations Service',
);
export const OrganizationsInvitationsApiAdapterSymbol: unique symbol = Symbol(
	'Organizations Invitations API Adapter',
);

export const organizationsModule = {
	[OrganizationsStoreSymbol]: asFunction(() => useOrganizationsStore()).singleton(),
	[OrganizationsApiAdapterSymbol]: asClass(OrganizationsApiAdapter),
	[OrganizationsServiceSymbol]: asClass(OrganizationsService),
	[OrganizationsWebsocketsServiceSymbol]: asClass(OrganizationsWebsocketsService),
	[OrganizationsInvitationsServiceSymbol]: asClass(OrganizationsInvitationsService),
	[OrganizationsInvitationsApiAdapterSymbol]: asClass(OrganizationsInvitationsApiAdapter),
};

export function useOrganizationsModule() {
	const container = useDependenciesContainer();
	return {
		organizationsService: container.resolve(OrganizationsServiceSymbol) as OrganizationsService,
		organizationsInvitationsService: container.resolve(
			OrganizationsInvitationsServiceSymbol,
		) as OrganizationsInvitationsService,
		organizationsStore: container.resolve(OrganizationsStoreSymbol) as IOrganizationStore,
	};
}
