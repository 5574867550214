import { defineStore } from 'pinia';

import { type CommitmentWithIncludes } from '@modules/commitments/types/commitments.types';
import { TimeframeData } from '@modules/timeframes/types/timeframes.types';
import { ensureNumber } from '@utils/variables';

export const TIMEFRAMES_STORE_NAME = 'timeframes';

export type TimeframesMapsKey = string;

export type TimeframesCommitmentsMap = {
	[index: TimeframeData['signature']]: {
		timeframeData: TimeframeData;
		commitmentIds: CommitmentWithIncludes['id'][];
	};
};

export type ITimeframesStore = ReturnType<typeof useTimeframesStore>;
export const useTimeframesStore = defineStore(TIMEFRAMES_STORE_NAME, {
	state: () => ({
		maps: <{ [index: TimeframesMapsKey]: TimeframesCommitmentsMap }>{},
	}),
	getters: {},
	actions: {
		setupMap(key: TimeframesMapsKey) {
			if (!this.maps[key]) {
				this.maps[key] = {};
			}
		},
		setTimeframesMap(key: TimeframesMapsKey, timeframesMap: TimeframesCommitmentsMap): boolean {
			if (!this.maps[key]) {
				this.setupMap(key);
			}

			this.maps[key] = timeframesMap;

			return true;
		},
		setCommitmentsToTimeframe(
			key: TimeframesMapsKey,
			timeframeSignature: TimeframeData['signature'],
			commitmentIds: CommitmentWithIncludes['id'][],
		) {
			this.maps[key][timeframeSignature].commitmentIds = commitmentIds;
		},

		addCommitmentToTimeframe(
			key: TimeframesMapsKey,
			timeframeSignature: TimeframeData['signature'],
			commitmentId: CommitmentWithIncludes['id'],
		) {
			if (!this.maps[key] || !this.maps[key][timeframeSignature]) {
				return undefined;
			}

			this.maps[key][timeframeSignature].commitmentIds.push(commitmentId);
		},

		removeCommitmentFromTimeframe(
			key: TimeframesMapsKey,
			timeframeSignature: TimeframeData['signature'],
			commitmentId: CommitmentWithIncludes['id'],
		) {
			if (this.maps[key]) {
				this.setCommitmentsToTimeframe(
					key,
					timeframeSignature,
					this.maps[key][timeframeSignature].commitmentIds.filter((id) => {
						return ensureNumber(id) !== ensureNumber(commitmentId);
					}),
				);

				return true;
			}
		},
	},
});
