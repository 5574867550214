import { inject } from 'vue';
import {
	type DependenciesContainer,
	DependenciesContainerIdentifier,
} from './dependencies.container';
import { MissingDependencyError } from '@common/errors/RuntimeErrors';

export function useDependenciesContainer() {
	const container = inject(DependenciesContainerIdentifier) as DependenciesContainer;

	if (!container) throw new MissingDependencyError('Dependencies Container');

	return container;
}
