import Bugsnag from '@bugsnag/js';

import { ILogger } from '../types/logger.types';

export class LoggerExternal implements ILogger {
	error(err: Error) {
		Bugsnag.notify(err);
	}
	warn(input: any) {}
	info(input: any) {}
	debug(input: any) {}
	log(input: any) {}
}
