import { asClass } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { AgendasService } from '@modules/agendas/services/agendas.service';

export const AgendasServiceSymbol: unique symbol = Symbol('Agendas Service');

export const agendasModule = {
	[AgendasServiceSymbol]: asClass(AgendasService),
};

export function useAgendasModule() {
	const container = useDependenciesContainer();
	return {
		agendasService: container.resolve(AgendasServiceSymbol) as AgendasService,
	};
}
