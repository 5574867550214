import { CommitmentWithIncludes } from '@modules/commitments/types/commitments.types';
import { useWebsocketListener } from '@infrastructure/websockets/composables/useWebsocketListener';
import {
	CommitmentCreatedEventKey,
	useCommitmentCreatedEvent,
} from '@modules/commitments/events/commitment-created.event';
import {
	CommitmentUpdatedEventKey,
	useCommitmentUpdatedEvent,
} from '@modules/commitments/events/commitment-updated.event';
import {
	CommitmentDeletedEventKey,
	useCommitmentDeletedEvent,
} from '@modules/commitments/events/commitment-deleted.event';

const COMMITMENT_CREATED_WEBSOCKET_EVENT = 'commitmentCreated';
const COMMITMENT_UPDATED_WEBSOCKET_EVENT = 'commitmentUpdated';
const COMMITMENT_DELETED_WEBSOCKET_EVENT = 'commitmentDeleted';

export class CommitmentsWebsocketsService {
	public setupFrontendEvents(context: string) {
		this.useOnCommitmentCreated(context, (commitment) => {
			useCommitmentCreatedEvent.emit(CommitmentCreatedEventKey, { commitment });
		});
		this.useOnCommitmentUpdated(context, (commitments) => {
			useCommitmentUpdatedEvent.emit(CommitmentUpdatedEventKey, { commitments });
		});
		this.useOnCommitmentDeleted(context, (commitment) => {
			useCommitmentDeletedEvent.emit(CommitmentDeletedEventKey, { commitment });
		});
	}

	public useOnCommitmentCreated(
		context: string,
		callback: (commitment: CommitmentWithIncludes) => void,
	) {
		return useWebsocketListener(COMMITMENT_CREATED_WEBSOCKET_EVENT, context, callback);
	}

	public useOnCommitmentUpdated(
		context: string,
		callback: (commitments: { new: CommitmentWithIncludes; old: CommitmentWithIncludes }) => void,
	) {
		return useWebsocketListener(COMMITMENT_UPDATED_WEBSOCKET_EVENT, context, callback);
	}

	public useOnCommitmentDeleted(
		context: string,
		callback: (commitment: CommitmentWithIncludes) => void,
	) {
		return useWebsocketListener(COMMITMENT_DELETED_WEBSOCKET_EVENT, context, callback);
	}
}
