import { EnvErrorInvalidEnvironment, EnvErrorMissingVariable } from './env.errors';

export interface IEnv {
	DEV: boolean;
	PROD: boolean;
	TEST: boolean;
	API_URL: string;
	WEBSOCKET_URL: string;
	DEV_DISPLAY_COMMITMENT_DEBUG_INFO: boolean;
	DEV_DISPLAY_TIMEFRAMES_DEBUG_INFO: boolean;
	BUGSNAG_API_KEY: string;
	FORMKIT_KEY: string;
	DISCORD_URL: string;
	SUPPORT_EMAIL: string;
}

export function makeEnv(): IEnv {
	const env = {
		DEV: parseAsBoolean('DEV', import.meta.env.DEV, false),
		PROD: parseAsBoolean('PROD', import.meta.env.PROD, false),
		TEST: parseAsBoolean('TEST', import.meta.env.MODE === 'test', false),
		API_URL: parseAsString('API_URL', import.meta.env.DLB_API_URL),
		WEBSOCKET_URL: parseAsString('WEBSOCKET_URL', import.meta.env.DLB_WEBSOCKET_URL),
		DEV_DISPLAY_COMMITMENT_DEBUG_INFO: parseAsBoolean(
			'DEV_DISPLAY_COMMITMENT_DEBUG_INFO',
			import.meta.env.DEV ? import.meta.env.DLB_DEV_DISPLAY_COMMITMENT_DEBUG_INFO : false,
			false,
		),
		DEV_DISPLAY_TIMEFRAMES_DEBUG_INFO: parseAsBoolean(
			'DEV_DISPLAY_TIMEFRAMES_DEBUG_INFO',
			import.meta.env.DEV ? import.meta.env.DLB_DEV_DISPLAY_TIMEFRAMES_DEBUG_INFO : false,
			false,
		),
		BUGSNAG_API_KEY: parseAsString('BUGSNAG_API_KEY', import.meta.env.DLB_BUGSNAG_API_KEY),
		FORMKIT_KEY: parseAsString('FORMKIT_KEY', import.meta.env.DLB_FORMKIT_KEY),
		DISCORD_URL: parseAsString(
			'DISCORD_URL',
			import.meta.env.DLB_DISCORD_URL,
			'https://discord.gg/u4dV9Wuj',
		),
		SUPPORT_EMAIL: parseAsString(
			'SUPPORT_EMAIL',
			import.meta.env.DLB_DISCORD_URL,
			'support@dailyboard.io',
		),
	};

	validateEnv(env);

	return env;
}

export const ENV = makeEnv();

function validateEnv(env: IEnv): void {
	if (!env.DEV && !env.PROD && !env.TEST) {
		throw new EnvErrorInvalidEnvironment();
	}
}

function parseAsString(name: keyof IEnv, source: unknown, defaultValue?: string): string {
	if (!!source) {
		return String(source);
	} else if (typeof defaultValue === 'string') {
		return defaultValue;
	}

	throw new EnvErrorMissingVariable(name);
}

function parseAsBoolean(name: keyof IEnv, source: unknown, defaultValue?: boolean): boolean {
	if (!!source) {
		return typeof source === 'boolean' ? source : source === 'true';
	} else if (typeof defaultValue === 'boolean') {
		return defaultValue;
	}

	throw new EnvErrorMissingVariable(name);
}
