import {
	DateTimeUnit,
	ITimeframeModel,
	TimeframeData,
	TimeframeDependecies,
	TimeframesOptions,
} from '@modules/timeframes/types/timeframes.types';
import { useIncludesNow } from '@modules/timeframes/composables/useIncludesNow';
import { areTimezonesOffsetsEqual, getLocalTimezone } from '@utils/datetime';

export abstract class TimeframeModelBase implements ITimeframeModel {
	protected dateTimeService;
	protected abstract type: TimeframeData['type'];
	protected abstract timeUnit: DateTimeUnit;

	constructor(dependencies: TimeframeDependecies) {
		this.dateTimeService = dependencies.dateTimeService;
	}

	getTimeframe(date: Date, timezone: string = getLocalTimezone()): TimeframeData {
		const from = this.getTimeframeBase(date, timezone);
		const to = this.getTimeframeEnd(date, timezone);
		return {
			from: from.getTime(),
			to: to.getTime(),
			signature: `${from}_${to}`,
			name: this.getTimeframeName(from, to, timezone),
			type: this.type,
			includesNow: useIncludesNow(from, to),
		};
	}
	getTimeframeName(from: Date, to: Date, timezone: string = getLocalTimezone()): string {
		const localTimezone = getLocalTimezone();
		if (areTimezonesOffsetsEqual(localTimezone, timezone)) {
			return this.dateTimeService.formatShortWithWeekday(from, localTimezone);
		}

		return `${this.dateTimeService.formatShortWithWeekdayAndTime(
			from,
			localTimezone,
		)} - ${this.dateTimeService.formatShortWithWeekdayAndTime(to, localTimezone)}`;
	}
	getTimeframeBase(date: Date, timezone: string = getLocalTimezone()): Date {
		return this.dateTimeService.startOf(date, this.timeUnit, timezone);
	}
	getTimeframeEnd(date: Date, timezone: string = getLocalTimezone()): Date {
		return this.dateTimeService.endOf(date, this.timeUnit, timezone);
	}
	getNextBase(
		date: Date,
		timezone: string = getLocalTimezone(),
		options?: TimeframesOptions,
	): Date {
		let nextBase = this.dateTimeService.plus(date, { [this.timeUnit]: 1 }, timezone);

		if (!options || !options.exclude || typeof options.exclude !== 'object') {
			return nextBase;
		}

		while (this.dateTimeService.hasAnyProperties(nextBase, options.exclude, timezone)) {
			nextBase = this.dateTimeService.plus(
				nextBase,
				{
					[this.timeUnit]: 1,
				},
				timezone,
			);
		}

		return nextBase;
	}
	getPreviousBase(
		date: Date,
		timezone: string = getLocalTimezone(),
		options?: TimeframesOptions,
	): Date {
		let previousBase = this.dateTimeService.minus(date, { [this.timeUnit]: 1 }, timezone);

		if (!options || !options.exclude || typeof options.exclude !== 'object') {
			return previousBase;
		}

		while (this.dateTimeService.hasAnyProperties(previousBase, options.exclude, timezone)) {
			previousBase = this.dateTimeService.minus(
				previousBase,
				{
					[this.timeUnit]: 1,
				},
				timezone,
			);
		}

		return previousBase;
	}
	getTimeframesCountBetweenDates(
		from: Date,
		to: Date,
		timezone: string = getLocalTimezone(),
	): number {
		const intervalLength = this.dateTimeService.intervalLength(from, to, this.timeUnit, timezone);
		return Math.floor(intervalLength);
	}
}
