<template>
	<Suspense>
		<div>
			<router-view :key="commonStore.appKey" />
		</div>
	</Suspense>
</template>

<script setup lang="ts">
import { ENV } from '@common/env';
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import { useThemes } from '@ui/composables/useThemes';
import { useCommonStore } from '@common/store/common.store';

useThemes();
const { t } = useI18n();
const commonStore = useCommonStore();

useHead({
	titleTemplate: t('common.headTitleTemplate'),
	link: [
		{
			rel: 'icon',
			type: 'image/x-icon',
			href: ENV.DEV ? '/favicon_dev.ico' : '/favicon.ico',
		},
	],
});
</script>
