import { defineStore } from 'pinia';
import { usePreferredDark, useStorage } from '@vueuse/core';

export const COMMON_STORE_NAME = 'common';
export const useCommonStore = defineStore(COMMON_STORE_NAME, {
	state: () => ({
		darkMode: useStorage('darkMode', usePreferredDark().value),
		appKey: 1,
		routerViewKey: 1,
		mainNavKey: 1,
	}),
	actions: {
		bumpAppKey(): void {
			this.appKey++;
		},
		bumpRouterViewKey(): void {
			this.routerViewKey++;
		},
		bumpMainNavKey(): void {
			this.mainNavKey++;
		},
	},
});
