import { asClass } from 'awilix';

import { DatetimeService } from '@infrastructure/datetime/services/datetime.service';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

export const DatetimeServiceSymbol: unique symbol = Symbol('Date Time Service');

export const datetimeModule = {
	[DatetimeServiceSymbol]: asClass(DatetimeService).singleton(),
};

export function useDatetimeModule() {
	const container = useDependenciesContainer();
	return {
		datetimeService: container.resolve(DatetimeServiceSymbol) as DatetimeService,
	};
}
