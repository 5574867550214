export function objectPick<T extends Object, K extends keyof T, A extends K[]>(
	obj: T,
	props: A,
): Pick<T, A[number]> {
	const result = {} as Pick<T, A[number]>;

	for (const prop of props) {
		result[prop] = obj[prop];
	}

	return result;
}

export function objectOmit<T extends Object, K extends keyof T, A extends K[]>(
	obj: T,
	props: A,
): Omit<T, A[number]> {
	const result = { ...obj };

	for (const prop of props) {
		delete result[prop];
	}

	return result;
}

export function isNonemptyObject(input: unknown) {
	return input !== null && typeof input === 'object' && !isObjectEmpty(input);
}

export function isObjectEmpty(obj: object): boolean {
	for (const x in obj) return false;
	return true;
}

export function ensureNumber(input: string | number): number {
	return typeof input === 'number' ? input : Number.parseInt(input);
}

export function isNonEmptyString(input: unknown) {
	return typeof input === 'string' && input.trim().length > 0;
}
