<template>
	<div :class="['flex items-center justify-center', containerClasses]">
		<BaseFontAwesomeIcon :icon="icon" :class="[iconClasses]" />
	</div>
</template>

<script setup lang="ts">
import { AvailableIcons } from '@plugins/fontawesome.plugin';
import { computed } from 'vue';

export type IconSizes = 'xsmall' | 'small' | 'default' | 'large' | 'xlarge';

const props = withDefaults(
	defineProps<{
		icon: AvailableIcons;
		size?: IconSizes;
	}>(),
	{
		size: 'default',
	},
);

interface SizeClasses {
	container: string;
	icon: string;
}

const SIZES_CLASSES: { [key in typeof props.size]: SizeClasses } = {
	xsmall: { container: 'h-4 w-4', icon: 'h-2.5 w-2.5' },
	small: { container: 'h-5 w-5', icon: 'h-3 w-3' },
	default: { container: 'h-6 w-6', icon: 'h-4 w-4' },
	large: { container: 'h-7 w-7', icon: 'h-5 w-5' },
	xlarge: { container: 'h-10 w-10', icon: 'h-8 w-8' },
};

const containerClasses = computed(() => SIZES_CLASSES[props.size].container);
const iconClasses = computed(() => SIZES_CLASSES[props.size].icon);
</script>
