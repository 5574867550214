import { onBeforeUnmount, onMounted } from 'vue';
import { useWebsocketsModule } from '../websockets.module';

export function useWebsocketListener<TArg, TReturn>(
	eventName: string,
	context: string,
	callback: (arg: TArg) => TReturn,
) {
	const { websocketsService } = useWebsocketsModule();

	onMounted(() => {
		websocketsService.on(eventName, context, callback);
	});

	onBeforeUnmount(() => {
		websocketsService.off(eventName, context, callback);
	});
}
