import ky, { HTTPError, type KyResponse, type Options } from 'ky';
import { EVENT_UNAUTHENTICATED_REQUEST } from '@common/events';
import { useAuthStore } from '@modules/auth/stores/auth.store';
import { IDeserializer } from '@infrastructure/json/deserializers/deserializer.interface';
import { DeserializerSymbol } from '@infrastructure/json/json.module';
import { CustomHttpError } from '../errors/http.errors';

export const QUERY_PARAMS_SEPARATOR = ',';

export interface HttpServiceOptions extends Options {}

export interface IHttpService {
	get(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	post(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	put(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	patch(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	delete(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	json<T>(request: Promise<KyResponse>): Promise<T>;
}

export class HttpService implements IHttpService {
	private options: HttpServiceOptions = {};
	private deserializer;
	private authStore;
	private http;

	constructor(args: { options?: HttpServiceOptions; [DeserializerSymbol]: IDeserializer }) {
		if (args.options) {
			this.options = args.options;
		}

		this.deserializer = args[DeserializerSymbol];

		// TODO: Remove this dependency!
		this.authStore = useAuthStore();

		this.http = ky.create({
			hooks: {
				beforeRequest: [
					(request) => {
						request.headers.set('Authorization', `Bearer ${this.authStore.getAccessToken()}`);
					},
				],
				afterResponse: [
					(_request, _options, response) => {
						if (response.status === 401) {
							const unauthenticatedRequestEvent = new CustomEvent(EVENT_UNAUTHENTICATED_REQUEST);
							window.dispatchEvent(unauthenticatedRequestEvent);
						}
					},
				],
			},
		});
	}

	public async json<T>(request: Promise<KyResponse>): Promise<T> {
		const response = await request;
		const json: { data: Object | Array<Object> } = await response.json();

		return this.deserializer.deserialize<T>(json);
	}

	public async get(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.get(url, { ...this.options, ...options });
		} catch (err) {
			throw this.parseError(err);
		}
	}
	public async post(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.post(url, { ...this.options, ...options });
		} catch (err) {
			throw this.parseError(err);
		}
	}
	public async put(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.put(url, { ...this.options, ...options });
		} catch (err) {
			throw this.parseError(err);
		}
	}
	public async patch(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.patch(url, { ...this.options, ...options });
		} catch (err) {
			throw this.parseError(err);
		}
	}
	public async delete(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.delete(url, { ...this.options, ...options });
		} catch (err) {
			throw this.parseError(err);
		}
	}

	private parseError(err: unknown) {
		if (err instanceof HTTPError) {
			return new CustomHttpError(err.response, err.request, err.options);
		}
		return err;
	}

	private setHeader(key: string, value: string): void {
		if (!Object.hasOwn(this.options, 'headers')) {
			this.options.headers = {};
		}

		this.options.headers = {
			...this.options.headers,
			...{ key, value },
		};
	}
}
