import { ApiError } from '@common/errors/ApiError';

export class AuthInvalidCredentialsApiError extends ApiError {
	constructor() {
		super(401, 'Provided credentials are invalid.');
	}
}

export class AuthEmailAlreadyTakenApiError extends ApiError {
	constructor() {
		super(409, 'This email is already taken');
	}
}

export class AuthInvalidPasswordApiError extends ApiError {
	constructor() {
		super(400, 'Invalid password');
	}
}

export class AuthUnauthenticatedApiError extends ApiError {
	constructor() {
		super(401, 'User is not authenticated. Login is required.');
	}
}
