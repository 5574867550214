import { TimeframeDependecies } from '../types/timeframes.types';
import { TimeframeModelBase } from './Timeframe.model';
import { TimeframeData, DateTimeUnit } from '../types/timeframes.types';

export class TimeframeD1 extends TimeframeModelBase {
	protected type: TimeframeData['type'];
	protected timeUnit: DateTimeUnit;

	constructor(dependencies: TimeframeDependecies) {
		super(dependencies);
		this.type = 'D1';
		this.timeUnit = 'day';
	}
}
