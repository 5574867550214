import { AuthApiAdapterSymbol, AuthStoreSymbol } from '@modules/auth/auth.module';
import {
	type LoginUserRequestDto,
	type CreateUserRequestDto,
	type CreateUserResponseDto,
} from '../types/auth.types';
import { type AuthApiAdapter } from '../api/auth.api';
import { IAuthStore, useAuthStore } from '../stores/auth.store';
import { UsersServiceSymbol } from '@modules/users/users.module';
import { UsersService } from '@modules/users/services/users.service';

export class AuthService {
	private readonly authApiAdapter;
	private readonly authStore;
	private readonly usersService;

	constructor(dependencies: {
		[AuthApiAdapterSymbol]: AuthApiAdapter;
		[AuthStoreSymbol]: IAuthStore;
		[UsersServiceSymbol]: UsersService;
	}) {
		this.authApiAdapter = dependencies[AuthApiAdapterSymbol];
		this.authStore = dependencies[AuthStoreSymbol];
		this.usersService = dependencies[UsersServiceSymbol];
	}

	async login(data: LoginUserRequestDto): Promise<boolean> {
		const res = await this.authApiAdapter.login(data);

		this.authStore.setAccessToken(res.accessToken);

		return true;
	}

	async createUser(data: CreateUserRequestDto): Promise<CreateUserResponseDto> {
		return this.authApiAdapter.createUser(data);
	}

	logout(): void {
		this.authStore.clearAccessToken();
		this.usersService.resetAuthUser();
	}

	static get isLoggedIn(): boolean {
		const authStore = useAuthStore();
		return authStore.hasAccessToken();
	}
}
