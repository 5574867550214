import { type App } from 'vue';

import { createAutoHeightTextareaPlugin } from '@formkit/addons';
import {
	arrowDown,
	arrowUp,
	check,
	circle,
	close,
	date,
	down,
	fileDoc,
	left,
	right,
	spinner,
	star,
	trash,
} from '@formkit/icons';
import { createProPlugin, inputs } from '@formkit/pro';
import { generateClasses } from '@formkit/themes';
import { defaultConfig as formkitDefaultConfig, plugin as formkitPlugin } from '@formkit/vue';

import { ENV } from '@common/env';
import formkitTheme from '@ui/components/forms/formkitTheme';

export function useFormkit(app: App) {
	const pro = createProPlugin(ENV.FORMKIT_KEY, inputs);

	app.use(
		formkitPlugin,
		formkitDefaultConfig({
			config: {
				classes: generateClasses(formkitTheme),
			},
			plugins: [pro, createAutoHeightTextareaPlugin()],
			icons: {
				arrowDown,
				arrowUp,
				close,
				checkboxDecorator: check,
				fileItem: fileDoc,
				fileRemove: close,
				noFiles: fileDoc,
				radioDecorator: circle,
				select: down,
				spinner,
				star,
				trash,
				date,
				left,
				right,
			},
		}),
	);
}
