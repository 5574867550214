import { asClass } from 'awilix';

import { PlainApiDeserializer } from '@infrastructure/json/deserializers/plain-api.deserializer';

export const DeserializerSymbol: unique symbol = Symbol('Deserializer');

const deserilizerClass = PlainApiDeserializer;

export const jsonModule = {
	[DeserializerSymbol]: asClass(deserilizerClass).singleton(),
};
