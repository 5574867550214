import {
	type AwilixContainer,
	type Constructor,
	type NameAndRegistrationPair,
	type FunctionReturning,
	createContainer,
	asClass,
	asFunction,
	asValue,
	Lifetime,
} from 'awilix';

export type DependenciesContainerResolutionKey = string | number | symbol;

export const DependenciesLifetimeMap = {
	transient: Lifetime.TRANSIENT,
	scoped: Lifetime.SCOPED,
	singleton: Lifetime.SINGLETON,
};
export type DependenciesLifetimeTypes = keyof typeof DependenciesLifetimeMap;

export const DependenciesContainerIdentifier = Symbol('Dependencies Container');

export class DependenciesContainer {
	private container: AwilixContainer;

	constructor() {
		this.container = createContainer();
		return this;
	}

	register(dependencies: NameAndRegistrationPair<any>) {
		this.container.register(dependencies);
		return this;
	}

	resolve(key: DependenciesContainerResolutionKey) {
		return this.container.resolve(key);
	}

	registerAsClass(
		key: DependenciesContainerResolutionKey,
		value: Constructor<any>,
		lifetime: DependenciesLifetimeTypes = 'transient',
	) {
		this.container.register({
			[key]: asClass(value, { lifetime: DependenciesLifetimeMap[lifetime] }),
		});

		return this;
	}

	registerAsFunction(
		key: DependenciesContainerResolutionKey,
		value: FunctionReturning<any>,
		lifetime: DependenciesLifetimeTypes = 'transient',
	) {
		this.container.register({
			[key]: asFunction(value, { lifetime: DependenciesLifetimeMap[lifetime] }),
		});

		return this;
	}

	registerAsValue(key: DependenciesContainerResolutionKey, value: any) {
		this.container.register({ [key]: asValue(value) });
		return this;
	}
}
