import { DatetimeServiceSymbol } from '@infrastructure/datetime/datetime.module';

import type { DatetimeService } from '@infrastructure/datetime/services/datetime.service';

import {
	type TimeframeData,
	type TimeframesOptions,
	type TimeframesTypes,
	type DateTimeUnit,
	timeframeModels,
} from '../types/timeframes.types';
import {
	CommitmentsFilterOptions,
	type CommitmentWithIncludes,
} from '@modules/commitments/types/commitments.types';
import {
	TimeframesMapsKey,
	type TimeframesCommitmentsMap,
	ITimeframesStore,
} from '../stores/timeframes.store';
import { CommitmentsServiceSymbol } from '@modules/commitments/commitments.module';
import { CommitmentsService } from '@modules/commitments/services/commitments.service';
import { TimeframesStoreSymbol } from '@modules/timeframes/timeframes.module';
import { useIncludesNow } from '../composables/useIncludesNow';
import { SupportedTimezone } from '@infrastructure/datetime/types/datetime.types';

export type TimeframesResolutionsConfig = {
	unit: DateTimeUnit;
	quantity: number;
	rangeUnit: DateTimeUnit;
};

const TimeframesResolutionsConfigMap: Record<TimeframesTypes, TimeframesResolutionsConfig> = {
	D1: {
		unit: 'day',
		quantity: 1,
		rangeUnit: 'week',
	},
	H1: {
		unit: 'hour',
		quantity: 1,
		rangeUnit: 'day',
	},
	W1: {
		unit: 'week',
		quantity: 1,
		rangeUnit: 'month',
	},
	M1: {
		unit: 'month',
		quantity: 1,
		rangeUnit: 'year',
	},
};

export class TimeframesService {
	private readonly commitmentsService;
	private readonly datetimeService;
	private readonly timeframesStore;

	private resolutionConfig;

	constructor(dependencies: {
		[DatetimeServiceSymbol]: DatetimeService;
		[CommitmentsServiceSymbol]: CommitmentsService;
		[TimeframesStoreSymbol]: ITimeframesStore;
	}) {
		this.datetimeService = dependencies[DatetimeServiceSymbol];
		this.commitmentsService = dependencies[CommitmentsServiceSymbol];
		this.timeframesStore = dependencies[TimeframesStoreSymbol];

		this.resolutionConfig = {} as TimeframesResolutionsConfig;
	}

	createTimeframes(config: {
		type: TimeframeData['type'];
		from: Date;
		to: Date | number;
		timezone: SupportedTimezone;
		options?: TimeframesOptions;
	}): TimeframeData[] {
		if (!timeframeModels[config.type] || typeof timeframeModels[config.type] !== 'function') {
			throw new Error(
				`Unknown timeframe type: ${config.type}. Check Timeframe.ts for available types.`,
			);
		}

		const timeframes = [] as TimeframeData[];

		const timeframeModel = timeframeModels[config.type]({
			dateTimeService: this.datetimeService,
		});
		const fromBase = timeframeModel.getTimeframeBase(config.from, config.timezone);

		let count = 0;
		if (config.to instanceof Date) {
			count = timeframeModel.getTimeframesCountBetweenDates(
				config.from,
				timeframeModel.getTimeframeEnd(config.to, config.timezone),
				config.timezone,
			);
		} else if (typeof config.to === 'number') {
			count = config.to;
		} else {
			throw new Error(`Wrong type of the to: parameter - it should be a Date or a number.`);
		}

		let currentBase = fromBase;
		for (let i = 0; i < count; i++) {
			timeframes.push(timeframeModel.getTimeframe(currentBase, config.timezone));
			currentBase = timeframeModel.getNextBase(currentBase, config.timezone, config.options);
		}

		return timeframes;
	}

	mapCommitmentsToTimeframes(commitments: CommitmentWithIncludes[], timeframes: TimeframeData[]) {
		const map = timeframes.reduce((map, data) => {
			return {
				...map,
				[data.signature]: {
					timeframeData: data,
					commitmentIds: [],
				},
			};
		}, {} as TimeframesCommitmentsMap);

		for (const commitment of commitments) {
			const matchingTimeframe = this.findMatchingTimeframeForCommitment(commitment, timeframes);

			if (matchingTimeframe) {
				map[matchingTimeframe.signature].commitmentIds.push(commitment.id);
			}
		}

		return map;
	}

	findMatchingTimeframeForCommitment(
		commitment: CommitmentWithIncludes,
		timeframes: TimeframeData[],
	): TimeframeData | undefined {
		const dueTime = new Date(commitment.dueDate).getTime();
		return timeframes.find((timeframe) => timeframe.from <= dueTime && timeframe.to >= dueTime);
	}

	async buildCommitmentsMap(
		key: TimeframesMapsKey,
		timeframes: TimeframeData[],
		commitmentsFilters: CommitmentsFilterOptions,
	): Promise<TimeframesCommitmentsMap> {
		const commitments = await this.commitmentsService.fetchCommitments({
			...commitmentsFilters,
			from: new Date(timeframes[0].from),
			to: new Date(timeframes[timeframes.length - 1].to),
		});

		this.timeframesStore.setTimeframesMap(
			key,
			this.mapCommitmentsToTimeframes(commitments, timeframes),
		);

		return this.timeframesStore.maps[key];
	}

	checkIfTimeframeIncludesNow(timeframe: TimeframeData) {
		return useIncludesNow(timeframe.from, timeframe.to);
	}

	// TODO: Move these functions to a model or a dedicated class
	setResolution(type: TimeframesTypes) {
		this.resolutionConfig = TimeframesResolutionsConfigMap[type];
	}

	calculateRangeStart(baseDate: Date, timezone: SupportedTimezone): Date {
		return this.datetimeService.startOf(baseDate, this.resolutionConfig.rangeUnit, timezone);
	}

	getStartOfPreviousTimeframe(
		baseDate: Date,
		timeframeType: TimeframesTypes,
		timezone: SupportedTimezone,
		timeframesOptions?: TimeframesOptions,
	) {
		const timeframeModel = timeframeModels[timeframeType]({
			dateTimeService: this.datetimeService,
		});
		return timeframeModel.getPreviousBase(baseDate, timezone, timeframesOptions);
	}

	getStartOfPreviousRange(
		baseDate: Date,
		timeframeType: TimeframesTypes,
		timezone: SupportedTimezone,
		timeframesOptions?: TimeframesOptions,
	) {
		const currentRangeStart = this.calculateRangeStart(baseDate, timezone);

		if (currentRangeStart.getTime() !== baseDate.getTime()) {
			return currentRangeStart;
		}

		return this.datetimeService.minus(
			currentRangeStart,
			{
				[this.resolutionConfig.rangeUnit]: 1,
			},
			timezone,
		);
	}

	getStartOfNextTimeframe(
		baseDate: Date,
		timeframeType: TimeframesTypes,
		timezone: SupportedTimezone,
		timeframesOptions?: TimeframesOptions,
	) {
		const timeframeModel = timeframeModels[timeframeType]({
			dateTimeService: this.datetimeService,
		});
		return timeframeModel.getNextBase(baseDate, timezone, timeframesOptions);
	}

	getStartOfNextRange(
		baseDate: Date,
		timeframeType: TimeframesTypes,
		timezone: SupportedTimezone,
		timeframesOptions?: TimeframesOptions,
	) {
		const nextRange = this.datetimeService.plus(
			baseDate,
			{
				[this.resolutionConfig.rangeUnit]: 1,
			},
			timezone,
		);
		return this.datetimeService.startOf(nextRange, this.resolutionConfig.rangeUnit, timezone);
	}
}
