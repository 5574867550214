import { asClass } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { ENV } from '@common/env';
import { LoggerExternal } from '@infrastructure/logger/loggers/LoggerExternal';
import { LoggerConsole } from '@infrastructure/logger/loggers/LoggerConsole';
import { ILogger } from './types/logger.types';

export const LoggerSymbol: unique symbol = Symbol('Logger');

export const loggerModule = {
	[LoggerSymbol]: ENV.DEV ? asClass(LoggerConsole) : asClass(LoggerExternal),
};

export function useLogger() {
	const container = useDependenciesContainer();
	return container.resolve(LoggerSymbol) as ILogger;
}
