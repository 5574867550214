import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { BoardsApiAdapter } from '@modules/boards/api/boards.api';
import { BoardsActorsService } from '@modules/boards/services/boards-actors.service';
import { BoardsCommitmentsService } from '@modules/boards/services/boards-commitments.service';
import { BoardsService } from '@modules/boards/services/boards.service';
import { IBoardsStore, useBoardsStore } from '@modules/boards/stores/boards.store';

export const BoardsApiAdapterSymbol: unique symbol = Symbol('Boards API Adapter');
export const BoardsServiceSymbol: unique symbol = Symbol('Boards Service');
export const BoardsActorsServiceSymbol: unique symbol = Symbol('Boards Actors Service');
export const BoardsCommitmentsServiceSymbol: unique symbol = Symbol('Boards Commitments Service');
export const BoardsStoreSymbol: unique symbol = Symbol('Boards Store');

export const boardsModule = {
	[BoardsApiAdapterSymbol]: asClass(BoardsApiAdapter),
	[BoardsServiceSymbol]: asClass(BoardsService),
	[BoardsActorsServiceSymbol]: asClass(BoardsActorsService),
	[BoardsCommitmentsServiceSymbol]: asClass(BoardsCommitmentsService),
	[BoardsStoreSymbol]: asFunction(() => useBoardsStore()).singleton(),
};

export function useBoardsModule() {
	const container = useDependenciesContainer();
	return {
		boardsService: container.resolve(BoardsServiceSymbol) as BoardsService,
		boardsStore: container.resolve(BoardsStoreSymbol) as IBoardsStore,
		boardsActorsService: container.resolve(BoardsActorsServiceSymbol) as BoardsActorsService,
		boardsCommitmentsService: container.resolve(
			BoardsCommitmentsServiceSymbol,
		) as BoardsCommitmentsService,
	};
}
