import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { CommitmentsApiAdapter } from '@modules/commitments/api/commitments.api';
import { CommitmentsService } from '@modules/commitments/services/commitments.service';
import {
	ICommitmentsStore,
	useCommitmentsStore,
} from '@modules/commitments/stores/commitments.store';
import { CommitmentsWebsocketsService } from '@modules/commitments/services/commitments-websockets.service';

export const CommitmentsApiAdapterSymbol: unique symbol = Symbol('Commitments API Adapter');
export const CommitmentsServiceSymbol: unique symbol = Symbol('Commitments Service');
export const CommitmentsWebsocketsServiceSymbol: unique symbol = Symbol(
	'Commitments Websockets Service',
);
export const CommitmentsStoreSymbol: unique symbol = Symbol('Commitments Store');

export const commitmentsModule = {
	[CommitmentsApiAdapterSymbol]: asClass(CommitmentsApiAdapter),
	[CommitmentsServiceSymbol]: asClass(CommitmentsService),
	[CommitmentsWebsocketsServiceSymbol]: asClass(CommitmentsWebsocketsService),
	[CommitmentsStoreSymbol]: asFunction(() => useCommitmentsStore()).singleton(),
};

export function useCommitmentsModule() {
	const container = useDependenciesContainer();
	return {
		commitmentsService: container.resolve(CommitmentsServiceSymbol) as CommitmentsService,
		commitmentsWebsocketService: container.resolve(
			CommitmentsWebsocketsServiceSymbol,
		) as CommitmentsWebsocketsService,
		commitmentsStore: container.resolve(CommitmentsStoreSymbol) as ICommitmentsStore,
	};
}
