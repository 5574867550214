import { BoardsApiAdapterSymbol, BoardsStoreSymbol } from '@modules/boards/boards.module';
import { OrganizationsServiceSymbol } from '@modules/organizations/organizations.module';
import { Actor } from '@modules/actors/types/actors.types';
import { OrganizationsService } from '@modules/organizations/services/organizations.service';
import { Organization } from '@modules/organizations/types/organizations.types';
import { type BoardsApiAdapter } from '../api/boards.api';
import { IBoardsStore } from '../stores/boards.store';
import { Board, type BoardWithIncludes, UpdateBoardFormData } from '../types/boards.types';
import { getLocalTimezone } from '@utils/datetime';

export class BoardsService {
	private readonly boardsApiAdapter;
	private readonly boardsStore;
	private readonly organizationsService;

	constructor(dependencies: {
		[BoardsApiAdapterSymbol]: BoardsApiAdapter;
		[BoardsStoreSymbol]: IBoardsStore;
		[OrganizationsServiceSymbol]: OrganizationsService;
	}) {
		this.boardsApiAdapter = dependencies[BoardsApiAdapterSymbol];
		this.boardsStore = dependencies[BoardsStoreSymbol];
		this.organizationsService = dependencies[OrganizationsServiceSymbol];
	}

	async fetchSharedBoardsInOrganization(orgId: Organization['id']): Promise<{
		[index: BoardWithIncludes['id']]: BoardWithIncludes;
	} | null> {
		const boards = await this.boardsApiAdapter.getAllBoards(orgId);

		if (!boards) return null;

		this.boardsStore.setBoards(boards, orgId);

		return this.boardsStore.getBoardsByOrgId(orgId);
	}

	async fetchPersonalBoardInOrganization(
		orgId: Organization['id'],
	): Promise<BoardWithIncludes | null> {
		const board = await this.boardsApiAdapter.getPersonalBoardInOrganization(orgId);

		if (!board) {
			return null;
		}

		this.boardsStore.setBoards([board], orgId);

		// Personal organization should only have one board
		return this.boardsStore.getBoardById(board.id, board.organization.id);
	}

	async fetchPersonalBoardInOrgForActor(
		orgId: Organization['id'],
		actorId: Actor['id'],
	): Promise<BoardWithIncludes | null> {
		const board = await this.boardsApiAdapter.getPersonalBoardInOrgForActor(orgId, actorId);

		if (!board) {
			return null;
		}

		this.boardsStore.setBoards([board], orgId);

		return this.boardsStore.getBoardById(board.id, board.organization.id);
	}

	async fetchBoardById(boardId: Board['id']): Promise<BoardWithIncludes | null> {
		const orgId = this.organizationsService.getCurrentOrganizationId();

		const board = this.boardsStore.getBoardById(boardId, orgId);

		if (board) return board;

		const fetchedBoard = await this.boardsApiAdapter.getBoard(boardId);

		if (!fetchedBoard) return null;

		this.boardsStore.setBoards([fetchedBoard], orgId);

		return this.boardsStore.getBoardById(boardId, orgId);
	}

	async fetchSharedBoardsForActor(orgId: Organization['id'], actorId: Actor['id']) {
		return await this.boardsApiAdapter.getSharedBoardsForActor(orgId, actorId);
	}

	async createBoard(orgId: Organization['id']): Promise<BoardWithIncludes> {
		return this.boardsApiAdapter.postBoard(orgId, {
			...this.boardsStore.newBoardForm,
			timezone: getLocalTimezone(),
		});
	}

	async deleteBoard(board: BoardWithIncludes): Promise<void> {
		await this.boardsApiAdapter.deleteBoard(board.id);
		this.boardsStore.removeBoard(board);
	}

	async updateBoard(
		boardId: Board['id'],
		data: UpdateBoardFormData,
	): Promise<BoardWithIncludes | null> {
		const fetchedBoard = await this.boardsApiAdapter.updateBoard(boardId, data);

		this.boardsStore.setBoards([fetchedBoard], fetchedBoard.organization.id);

		return this.boardsStore.getBoardById(boardId, fetchedBoard.organization.id);
	}
}
