export enum AlertType {
	DEFAULT = 'default',
	INFO = 'info',
	SUCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger',
}

export interface Alert {
	id?: string;
	title: string;
	message: string;
	type: AlertType;
	cta?: string;
	ctaCallback?: CallableFunction;
	autoDismiss?: boolean;
}

export interface UniqueAlert extends Alert {
	id: string; // ID is required for unique alerts
}
