import { reactive } from 'vue';

import i18n from '@plugins/i18n.plugin';

import { AlertsStoreSymbol } from '@infrastructure/alerts/alerts.module';
import { IAlertsStore } from '../stores/alerts.store';

import { AlertType, type Alert, UniqueAlert } from '../types/alerts.types';

export class AlertsService {
	private alertsStore: IAlertsStore;

	constructor(dependencies: { [AlertsStoreSymbol]: IAlertsStore }) {
		this.alertsStore = dependencies[AlertsStoreSymbol];
	}

	getAlerts(limit?: number): Alert[] {
		return reactive(this.alertsStore.getAlerts(limit));
	}

	addAlerts(alerts: Alert[]): void {
		this.alertsStore.addAlerts(alerts);
	}

	removeAlert(index: number): boolean {
		return this.alertsStore.removeAlert(index);
	}

	addUniqueAlert(alert: UniqueAlert): void {
		if (!this.alertsStore.hasUniqueAlert(alert.id)) {
			this.alertsStore.addAlerts([alert]);
		}
	}

	removeUniqueAlert(alertId: UniqueAlert['id']): void {
		this.alertsStore.removeUniqueAlert(alertId);
	}

	genericSuccessAlert(message: string | null = null) {
		const { t } = i18n.global;
		this.addAlerts([
			{
				title: t('common.genericSuccessTitle'),
				message: message || '',
				type: AlertType.SUCESS,
				autoDismiss: true,
			},
		]);
	}

	genericFailureAlert(message: string | null = null, autoDismiss = true) {
		const { t } = i18n.global;
		this.addAlerts([
			{
				title: t('common.oops'),
				message: message || t('common.genericErrorMessage'),
				type: AlertType.DANGER,
				autoDismiss: autoDismiss,
			},
		]);
	}
}
