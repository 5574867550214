import { AlertType } from '@infrastructure/alerts/types/alerts.types';
import i18n from '@plugins/i18n.plugin';
import { OrganizationsInvitationsApiAdapterSymbol } from '../organizations.module';
import { OrganizationsInvitationsApiAdapter } from '../api/organizations-invitations.api';
import { AlertsServiceSymbol } from '@infrastructure/alerts/alerts.module';
import { AlertsService } from '@infrastructure/alerts/services/alerts.service';
import { PendingInvitation, User } from '@modules/users/types/users.types';
import {
	OrganizationInvitationAcceptedKey,
	useOrganizationInvitationAccepted,
} from '../events/organization-invitation-accepted.event';
import { LoggerSymbol } from '@infrastructure/logger/logger.module';
import { ILogger } from '@infrastructure/logger/types/logger.types';
import { CustomHttpError } from '@infrastructure/http/errors/http.errors';

export class OrganizationsInvitationsService {
	private readonly organizationsInvitationsApiAdapter;
	private readonly alertsService;
	private readonly logger;

	constructor(dependencies: {
		[OrganizationsInvitationsApiAdapterSymbol]: OrganizationsInvitationsApiAdapter;
		[AlertsServiceSymbol]: AlertsService;
		[LoggerSymbol]: ILogger;
	}) {
		this.organizationsInvitationsApiAdapter =
			dependencies[OrganizationsInvitationsApiAdapterSymbol];
		this.alertsService = dependencies[AlertsServiceSymbol];
		this.logger = dependencies[LoggerSymbol];
	}

	public async checkPendingInvitations(user: User): Promise<void> {
		const { t } = i18n.global;

		const invitations = await this.organizationsInvitationsApiAdapter.checkPendingInvitations(
			user.id,
		);

		for (const invitation of invitations) {
			this.alertsService.addAlerts([
				{
					title: t('modules.users.invitations.alertHeader'),
					message: t('modules.users.invitations.alertMessage', {
						orgName: invitation.organization.name,
					}),
					type: AlertType.SUCESS,
					cta: t('modules.users.invitations.alertCta'),
					ctaCallback: async () => {
						await this.acceptInvitation(invitation.id);
					},
				},
			]);
		}
	}

	public async acceptInvitation(invitationId: PendingInvitation['id']): Promise<void> {
		const invitation = await this.organizationsInvitationsApiAdapter.acceptInvitation(invitationId);
		useOrganizationInvitationAccepted.emit(OrganizationInvitationAcceptedKey, { invitation });
	}

	public async autoAcceptInvitation(invitationId: string): Promise<void> {
		try {
			await this.acceptInvitation(invitationId);
		} catch (error) {
			const { t } = i18n.global;
			if (error instanceof CustomHttpError && error.response.status === 400) {
				this.alertsService.genericFailureAlert(
					t('modules.users.invitations.alertBadRequest'),
					false,
				);
				this.logger.error(`Account mismatch. Tried to accept invitation ID ${invitationId}`);
			} else if (error instanceof CustomHttpError && error.response.status === 404) {
				this.alertsService.genericFailureAlert(t('modules.users.invitations.alertNotFound'));
			} else {
				throw error;
			}
		}
	}
}
