import { type App } from 'vue';
import { ENV } from '@common/env';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { useUsersStore } from '@modules/users/stores/users.store';
import { getClientIdCookie } from '@utils/cookies';

export function useBugsnag(app: App) {
	const loadTime = new Date().toJSON();

	Bugsnag.start({
		apiKey: ENV.BUGSNAG_API_KEY,
		plugins: [new BugsnagPluginVue()],
		enabledReleaseStages: ['staging', 'production'],
		onError: function (event) {
			const { authUser } = useUsersStore();
			authUser && event.setUser(String(authUser.id), authUser.email);
			event.addMetadata('user', { cid: getClientIdCookie() });
			event.addMetadata('app', { loaded: loadTime });
		},
	});

	const bugsnag = Bugsnag.getPlugin('vue');
	if (bugsnag) {
		app.use(bugsnag);
	}
}
