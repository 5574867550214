import { TimeframeDependecies } from '../types/timeframes.types';
import { TimeframeModelBase } from './Timeframe.model';
import { TimeframeData, DateTimeUnit } from '../types/timeframes.types';

export class TimeframeW1 extends TimeframeModelBase {
	protected type: TimeframeData['type'];
	protected timeUnit: DateTimeUnit;

	constructor(dependencies: TimeframeDependecies) {
		super(dependencies);
		this.type = 'W1';
		this.timeUnit = 'week';
	}

	// TODO: Actualy implement week-specific logic
}
