import { ILogger } from '../types/logger.types';

/* eslint no-console: 0 */
export class LoggerConsole implements ILogger {
	error(err: Error) {
		console.error(err);
	}
	warn(input: any) {
		console.warn(input);
	}
	info(input: any) {
		console.info(input);
	}
	debug(input: any) {
		console.debug(input);
	}
	log(input: any) {
		console.log(input);
	}
}
