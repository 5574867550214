import { type Actor } from '@modules/actors/types/actors.types';
import { type ITimeframesStore } from '@modules/timeframes/stores/timeframes.store';

import {
	TimeframesServiceSymbol,
	TimeframesStoreSymbol,
} from '@modules/timeframes/timeframes.module';
import { TimeframesService } from '@modules/timeframes/services/timeframes.service';
import { getLocalTimezone } from '@utils/datetime';

export class AgendasService {
	private timeframesService;
	private timeframesStore;

	constructor(dependencies: {
		[TimeframesServiceSymbol]: TimeframesService;
		[TimeframesStoreSymbol]: ITimeframesStore;
	}) {
		this.timeframesService = dependencies[TimeframesServiceSymbol];
		this.timeframesStore = dependencies[TimeframesStoreSymbol];
	}

	async getCommitmentsForUser(id: Actor['id'], from: Date) {
		const timeframes = this.timeframesService.createTimeframes({
			type: 'D1',
			from,
			to: 7,
			timezone: getLocalTimezone(),
		});

		const mapKey = `agendas[${id}]`;

		await this.timeframesService.buildCommitmentsMap(mapKey, timeframes, {});

		return { timeframes, commitmentsMap: this.timeframesStore.maps[mapKey] };
	}
}
