import {
	CreateCommitmentDto,
	UpsertCommitmentRequestDto,
	type CommitmentWithIncludes,
	type CommitmentsFilterOptions,
	type CommitmentsSearchParams,
} from '../types/commitments.types';

import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { CustomHttpError } from '@infrastructure/http/errors/http.errors';
import {
	QUERY_PARAMS_SEPARATOR,
	type IHttpService,
} from '@infrastructure/http/services/http.service';

import { LoggerSymbol } from '@infrastructure/logger/logger.module';
import { ILogger } from '@infrastructure/logger/types/logger.types';
import { CommitmentsNotFoundApiError } from '../errors/commitments.errors';

export class CommitmentsApiAdapter {
	private httpService: IHttpService;
	private logger: ILogger;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService; [LoggerSymbol]: ILogger }) {
		this.httpService = dependencies[HttpServiceSymbol];
		this.logger = dependencies[LoggerSymbol];
	}

	public async getAllCommitments(): Promise<CommitmentWithIncludes[]> {
		const commitments = this.httpService.json<CommitmentWithIncludes[]>(
			this.httpService.get('commitments'),
		);

		return commitments;
	}

	public async getCommitment(id: CommitmentWithIncludes['id']): Promise<CommitmentWithIncludes> {
		const commitment = this.httpService.json<CommitmentWithIncludes>(
			this.httpService.get(`commitments/${id}`),
		);

		return commitment;
	}

	public async getCommitments(
		ids: CommitmentWithIncludes['id'][],
	): Promise<CommitmentWithIncludes[]> {
		const commitments = this.httpService.json<CommitmentWithIncludes[]>(
			this.httpService.get('commitments', {
				searchParams: { ids: ids.join(QUERY_PARAMS_SEPARATOR) },
			}),
		);

		return commitments;
	}

	public async fetchCommitments(options: CommitmentsFilterOptions) {
		const searchParams = this.buildSearchParamsFromOptions(options);

		const commitments = this.httpService.json<CommitmentWithIncludes[]>(
			this.httpService.get('commitments', {
				searchParams,
			}),
		);

		return commitments;
	}

	public async createCommitment(data: CreateCommitmentDto): Promise<CommitmentWithIncludes> {
		const result = await this.httpService.json<CommitmentWithIncludes>(
			this.httpService.post('commitments', { json: data }),
		);

		return result;
	}

	public async deleteCommitment(id: CommitmentWithIncludes['id']): Promise<boolean> {
		try {
			const response = await this.httpService.delete(`commitments/${id}`);
			return response.ok;
		} catch (err) {
			if (err instanceof CustomHttpError && err.response.status === 404) {
				throw new CommitmentsNotFoundApiError(id);
			}
			throw err;
		}
	}

	public async updateCommitment(
		id: CommitmentWithIncludes['id'],
		data: UpsertCommitmentRequestDto,
	): Promise<CommitmentWithIncludes> {
		try {
			const response: CommitmentWithIncludes = await this.httpService.json(
				this.httpService.patch(`commitments/${id}`, { json: data }),
			);
			return response;
		} catch (err) {
			if (err instanceof CustomHttpError && err.response.status === 404) {
				throw new CommitmentsNotFoundApiError(id);
			}
			throw err;
		}
	}

	private buildSearchParamsFromOptions(options: CommitmentsFilterOptions) {
		const searchParams = {} as CommitmentsSearchParams;

		if (Array.isArray(options.ids)) {
			searchParams.ids = options.ids.join(QUERY_PARAMS_SEPARATOR);
			delete options.ids;
		}

		if (typeof options.description !== 'undefined') {
			searchParams.description = options.description;
			delete options.description;
		}

		if (typeof options.boardId === 'number') {
			searchParams.boardId = options.boardId.toString();
			delete options.boardId;
		} else if (typeof options.boardId === 'undefined') {
			delete options.boardId;
		}

		if (options.from instanceof Date) {
			searchParams.from = options.from.getTime().toString();
			delete options.from;
		}

		if (options.to instanceof Date) {
			searchParams.to = options.to.getTime().toString();
			delete options.to;
		}

		if (Object.keys(options).length > 0) {
			this.logger.warn(
				`CommitmentsApiAdapter.filter() - Unrecognized options: ${Object.keys(options).join(', ')}`,
			);
		}

		return searchParams;
	}
}
