import { defineStore } from 'pinia';

import type { Actor } from '@modules/actors/types/actors.types';
import {
	CommitmentView,
	CommitmentWithIncludes,
} from '@modules/commitments/types/commitments.types';
import { Organization } from '@modules/organizations/types/organizations.types';
import type { TimeframeData } from '@modules/timeframes/types/timeframes.types';
import { timeframeTypes } from '@modules/timeframes/types/timeframes.types';
import type { BoardCommitmentsMap } from '../types/boards.types';
import {
	BoardType,
	BoardView,
	BoardWithIncludes,
	CreateBoardFormData,
} from '../types/boards.types';
import { ensureNumber } from '@utils/variables';

export const BOARDS_STORE_NAME = 'boards';

const BOARDS_NEW_FORM_INITIAL_STATE = {
	name: '',
	resolution: timeframeTypes.D1,
	exclude: {},
	view: BoardView.BOARD,
	type: BoardType.SHARED,
} as CreateBoardFormData;

const currentBoardDefault = {
	detailedCommitment: null as number | null,
	commitmentView: CommitmentView.FULL as CommitmentView,
};

export type IBoardsStore = ReturnType<typeof useBoardsStore>;
export const useBoardsStore = defineStore(BOARDS_STORE_NAME, {
	state: () => ({
		currentBoard: { ...currentBoardDefault },
		boards: {} as {
			[orgId: Organization['id']]: { [boardId: BoardWithIncludes['id']]: BoardWithIncludes };
		},
		boardMaps: {} as { [boardId: BoardWithIncludes['id']]: BoardCommitmentsMap },
		boardTimeframes: {} as { [boardId: BoardWithIncludes['id']]: TimeframeData[] },
		newBoardForm: {
			name: '',
			resolution: timeframeTypes.D1,
			exclude: {},
			view: BoardView.BOARD,
			type: BoardType.SHARED,
		} as CreateBoardFormData,
	}),
	getters: {
		getBoardById: (state) => {
			return (
				boardId: BoardWithIncludes['id'],
				orgId: Organization['id'],
			): BoardWithIncludes | null => {
				if (undefined === state.boards[orgId]) {
					return null;
				}

				return state.boards[orgId][boardId];
			};
		},
		getBoardsByOrgId: (state) => {
			return (
				orgId: Organization['id'],
			): { [boardId: BoardWithIncludes['id']]: BoardWithIncludes } | null =>
				state.boards[orgId] ? state.boards[orgId] : null;
		},
		getCommitmentsFromMap: (state) => {
			return (
				boardId: BoardWithIncludes['id'],
				timeframeSignature: TimeframeData['signature'],
				actorId: Actor['id'],
			): CommitmentWithIncludes['id'][] | null => {
				if (
					state.boardMaps[boardId] ||
					state.boardMaps[boardId][timeframeSignature] ||
					state.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId]
				) {
					return state.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId];
				}

				return null;
			};
		},
	},
	actions: {
		resetNewBoardForm() {
			this.newBoardForm = { ...BOARDS_NEW_FORM_INITIAL_STATE };
		},
		resetCurrentBoard() {
			this.currentBoard = { ...currentBoardDefault };
		},
		setCommitmentView(view: CommitmentView) {
			this.currentBoard.commitmentView = view;
		},
		setDetailedCommitment(commitmentId: CommitmentWithIncludes['id']) {
			this.currentBoard.detailedCommitment = commitmentId;
		},
		setBoards(boards: BoardWithIncludes[], orgId: Organization['id']): void {
			if (undefined === this.boards[orgId]) {
				this.boards[orgId] = {};
			}

			for (const board of boards) {
				this.boards[orgId][board.id] = board;
			}
		},
		removeBoard(board: BoardWithIncludes): void {
			delete this.boards[board.organization.id][board.id];
		},
		setBoardTimeframes(board: BoardWithIncludes, timeframes: TimeframeData[]): void {
			this.boardTimeframes[board.id] = timeframes;
		},
		setCommitmentsMap(boardId: BoardWithIncludes['id'], commitmentsMap: BoardCommitmentsMap) {
			this.boardMaps[boardId] = JSON.parse(JSON.stringify(commitmentsMap));
		},
		addCommitmentToMap(
			commitmentId: CommitmentWithIncludes['id'],
			boardId: BoardWithIncludes['id'],
			timeframeSignature: TimeframeData['signature'],
			actorId: Actor['id'],
		) {
			if (!this.getCommitmentsFromMap(boardId, timeframeSignature, actorId)) {
				return false;
			}

			if (
				!this.isCommitmentInTimeframeActorArray(commitmentId, boardId, timeframeSignature, actorId)
			) {
				this.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId].push(
					ensureNumber(commitmentId),
				);
			}
		},

		updateCommitmentsInMap(
			commitmentsIds: CommitmentWithIncludes['id'][],
			boardId: BoardWithIncludes['id'],
			timeframeSignature: TimeframeData['signature'],
			actorId: Actor['id'],
		) {
			if (!this.getCommitmentsFromMap(boardId, timeframeSignature, actorId)) {
				return false;
			}

			this.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId] = commitmentsIds;
		},

		isCommitmentInTimeframeActorArray(
			commitmentId: CommitmentWithIncludes['id'],
			boardId: BoardWithIncludes['id'],
			timeframeSignature: TimeframeData['signature'],
			actorId: Actor['id'],
		) {
			return this.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId].includes(
				commitmentId,
			);
		},

		removeCommitmentFromMap(
			commitmentId: CommitmentWithIncludes['id'],
			boardId: BoardWithIncludes['id'],
			timeframeSignature: TimeframeData['signature'],
			actorId: Actor['id'],
		) {
			if (!this.getCommitmentsFromMap(boardId, timeframeSignature, actorId)) {
				return false;
			}

			const existingCommitments =
				this.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId];

			this.boardMaps[boardId][timeframeSignature].actorsCommitments[actorId] =
				existingCommitments.filter((id) => id !== commitmentId);
		},

		moveCommitment(
			commitmentId: CommitmentWithIncludes['id'],
			boardId: BoardWithIncludes['id'],
			oldTimeframeSignature: TimeframeData['signature'],
			oldActorId: Actor['id'],
			newTimeframeSignature: TimeframeData['signature'],
			newActorId: Actor['id'],
		) {
			if (
				this.isCommitmentInTimeframeActorArray(
					commitmentId,
					boardId,
					oldTimeframeSignature,
					oldActorId,
				) &&
				!this.isCommitmentInTimeframeActorArray(
					commitmentId,
					boardId,
					newTimeframeSignature,
					newActorId,
				)
			) {
				this.removeCommitmentFromMap(commitmentId, boardId, oldTimeframeSignature, oldActorId);
				this.addCommitmentToMap(commitmentId, boardId, newTimeframeSignature, newActorId);
			}
		},
	},
});
