import { asClass } from 'awilix';

import { ENV } from '@common/env';
import { HttpService } from '@infrastructure/http/services/http.service';

const API_URL = ENV.API_URL;

export const HttpServiceSymbol: unique symbol = Symbol('Http Service');

export const httpModule = {
	[HttpServiceSymbol]: asClass(HttpService)
		.singleton()
		.inject(() => ({ options: { prefixUrl: API_URL, credentials: 'include' } })),
};
