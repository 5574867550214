import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { type IHttpService } from '@infrastructure/http/services/http.service';

import { type Actor } from '../types/actors.types';
import { Organization } from '@modules/organizations/types/organizations.types';

export class ActorsApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	public async getAllActorsInOrg(orgId: Organization['id']): Promise<Actor[]> {
		return this.httpService.json<Actor[]>(this.httpService.get(`org/${orgId}/actors`));
	}

	public async update(actorId: Actor['id'], actorData: Partial<Actor>): Promise<Actor> {
		return this.httpService.json<Actor>(
			this.httpService.patch(`actors/${actorId}`, { json: actorData }),
		);
	}

	public async uploadAvatar(actorId: Actor['id'], formData: FormData): Promise<Actor> {
		return this.httpService.json(
			this.httpService.post(`actors/${actorId}/avatar`, { body: formData }),
		);
	}

	public async removeAvatar(actorId: Actor['id']): Promise<Actor> {
		return this.httpService.json(this.httpService.delete(`actors/${actorId}/avatar`));
	}
}
