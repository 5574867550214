export function hasCommonElements<T>(array1: T[], array2: T[]): boolean {
	const setOfArray2 = new Set(array2);

	for (let i = 0; i < array1.length; i++) {
		if (setOfArray2.has(array2[i])) {
			return true;
		}
	}

	return false;
}
