import { UsersApiAdapterSymbol, UsersStoreSymbol } from '@modules/users/users.module';
import { type UsersApiAdapter } from '../api/users.api';
import { IUsersStore } from '../stores/users.store';
import { ActorsServiceSymbol } from '@modules/actors/actors.module';
import { ActorsService } from '@modules/actors/services/actors.service';
import { MissingAuthUserError } from '../errors/users.errors';
import { Actor } from '@modules/actors/types/actors.types';

export class UsersService {
	private readonly actorsService;
	private readonly usersApiAdapter;
	private readonly usersStore;

	constructor(dependencies: {
		[ActorsServiceSymbol]: ActorsService;
		[UsersApiAdapterSymbol]: UsersApiAdapter;
		[UsersStoreSymbol]: IUsersStore;
	}) {
		this.actorsService = dependencies[ActorsServiceSymbol];
		this.usersApiAdapter = dependencies[UsersApiAdapterSymbol];
		this.usersStore = dependencies[UsersStoreSymbol];
	}

	public async getAuthUser() {
		if (!this.usersStore.authUser) {
			await this.syncAuthUser();
		}

		const authUser = this.usersStore.authUser;

		if (!authUser) {
			throw new MissingAuthUserError();
		}

		return authUser;
	}

	public async syncAuthUser(): Promise<void> {
		const fetchedUser = await this.usersApiAdapter.getAuthUser();
		this.usersStore.setAuthUser(fetchedUser);
	}

	public resetAuthUser(): void {
		this.usersStore.resetAuthUser();
	}

	public async updateAuthUserActor(actorData: Partial<Actor>): Promise<void> {
		const actorId = this.usersStore.authUser?.actor.id;
		if (!actorId) return;

		const actorUpdated = await this.actorsService.update(actorId, actorData);

		if (this.usersStore.authUser) {
			this.usersStore.authUser.actor = actorUpdated;
		}
	}
}
