import { useEventBus, type EventBusKey } from '@vueuse/core';
import { CommitmentWithIncludes } from '../types/commitments.types';

export const CommitmentDeletedEventKey: EventBusKey<{ name: 'commitment_deleted' }> =
	Symbol('commitment_deleted');

export interface CommitmentDeletedEventPayload {
	commitment: CommitmentWithIncludes;
}

export const useCommitmentDeletedEvent = useEventBus<
	typeof CommitmentDeletedEventKey,
	CommitmentDeletedEventPayload
>(CommitmentDeletedEventKey);
