import { TimeframeW1 } from '../models/TimeframeW1';
import { TimeframeM1 } from '../models/TimeframeM1';
import { TimeframeD1 } from '../models/TimeframeD1';
import { TimeframeH1 } from '../models/TimeframeH1';
import { SupportedTimezone } from '@infrastructure/datetime/types/datetime.types';

export const timeframeTypes = {
	H1: 'H1',
	D1: 'D1',
	W1: 'W1',
	M1: 'M1',
} as const;
export type TimeframesTypes = keyof typeof timeframeTypes;

export type TimeframeData = {
	from: number;
	to: number;
	signature: string;
	name: string;
	type: keyof typeof timeframeTypes;
	includesNow: boolean;
};

export interface TimeframesOptions {
	exclude?: DateTimeProperties;
}

export interface ITimeframeModel {
	getTimeframe(base: Date, timezone: SupportedTimezone): TimeframeData;
	getTimeframeBase(date: Date, timezone: SupportedTimezone): Date;
	getTimeframeEnd(date: Date, timezone: SupportedTimezone): Date;
	getNextBase(date: Date, timezone: SupportedTimezone, options?: TimeframesOptions): Date;
	getPreviousBase(date: Date, timezone: SupportedTimezone, options?: TimeframesOptions): Date;
	getTimeframesCountBetweenDates(from: Date, to: Date, timezone: SupportedTimezone): number;
}

export const timeframeModels = {
	[timeframeTypes.H1]: (dependecies: TimeframeDependecies): ITimeframeModel =>
		new TimeframeH1(dependecies),
	[timeframeTypes.D1]: (dependecies: TimeframeDependecies): ITimeframeModel =>
		new TimeframeD1(dependecies),
	[timeframeTypes.W1]: (dependecies: TimeframeDependecies): ITimeframeModel =>
		new TimeframeW1(dependecies),
	[timeframeTypes.M1]: (dependecies: TimeframeDependecies): ITimeframeModel =>
		new TimeframeM1(dependecies),
} as const;

export type TimeframeDependecies = { dateTimeService: IDateTimeService };

export interface IDateTimeService {
	startOf(date: Date, unit: DateTimeUnit, timezone: SupportedTimezone): Date;
	endOf(date: Date, unit: DateTimeUnit, timezone: SupportedTimezone): Date;
	plus(date: Date, duration: Duration, timezone: SupportedTimezone): Date;
	minus(date: Date, duration: Duration, timezone: SupportedTimezone): Date;
	intervalLength(from: Date, to: Date, unit: DateTimeUnit, timezone: SupportedTimezone): number;
	toString(date: Date, format: DateTimeFormatOptions, timezone: SupportedTimezone): string;
	hasAnyProperties(date: Date, options: DateTimeProperties, timezone: SupportedTimezone): boolean;
	formatShortWithWeekday(date: Date, timezone: SupportedTimezone): string;
	formatShortWithWeekdayAndTime(date: Date, timezone: SupportedTimezone): string;
	formatTime(date: Date, timezone: SupportedTimezone): string;
	formatFullDate(date: Date, timezone: SupportedTimezone): string;
}

type DateTimePropertiesPrototype = {
	[index: string]: (string | number)[] | undefined;
};

export interface DateTimeProperties extends DateTimePropertiesPrototype {
	weekday?: (1 | 2 | 3 | 4 | 5 | 6 | 7)[];
	month?: (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12)[];
}

export type DateTimeUnit =
	| 'year'
	| 'quarter'
	| 'month'
	| 'week'
	| 'day'
	| 'hour'
	| 'minute'
	| 'second'
	| 'millisecond';

export interface Duration {
	year?: number | undefined;
	quarter?: number | undefined;
	month?: number | undefined;
	week?: number | undefined;
	day?: number | undefined;
	hour?: number | undefined;
	minute?: number | undefined;
	second?: number | undefined;
	millisecond?: number | undefined;
}

export interface DateTimeFormatOptions {
	localeMatcher?: 'best fit' | 'lookup' | undefined;
	weekday?: 'long' | 'short' | 'narrow' | undefined;
	era?: 'long' | 'short' | 'narrow' | undefined;
	year?: 'numeric' | '2-digit' | undefined;
	month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
	day?: 'numeric' | '2-digit' | undefined;
	hour?: 'numeric' | '2-digit' | undefined;
	minute?: 'numeric' | '2-digit' | undefined;
	second?: 'numeric' | '2-digit' | undefined;
	timeZoneName?:
		| 'short'
		| 'long'
		| 'shortOffset'
		| 'longOffset'
		| 'shortGeneric'
		| 'longGeneric'
		| undefined;
	formatMatcher?: 'best fit' | 'basic' | undefined;
	hour12?: boolean | undefined;
	timeZone?: string | undefined;
}
