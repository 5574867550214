import { ActorsApiAdapterSymbol, ActorsStoreSymbol } from '@modules/actors/actors.module';
import { type Actor } from '../types/actors.types';
import { type ActorsApiAdapter } from '../api/actors.api';
import { Organization } from '@modules/organizations/types/organizations.types';
import { IActorsStore } from '../stores/actors.store';

export class ActorsService {
	private readonly actorsApiAdapter;
	private readonly actorsStore;

	constructor(dependencies: {
		[ActorsApiAdapterSymbol]: ActorsApiAdapter;
		[ActorsStoreSymbol]: IActorsStore;
	}) {
		this.actorsApiAdapter = dependencies[ActorsApiAdapterSymbol];
		this.actorsStore = dependencies[ActorsStoreSymbol];
	}

	getUnassigned(): Actor {
		// TODO: This probably shouldn't be here, but I don't see a better place yet.
		return {
			id: 0,
			firstName: 'Unassigned',
			lastName: '',
			avatarUrl:
				'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png',
			organizationId: null,
			fullName: 'Unassigned',
		};
	}

	async fetchAllActorsInOrg(orgId: Organization['id']): Promise<Actor[]> {
		const actors = await this.actorsApiAdapter.getAllActorsInOrg(orgId);

		if (actors) {
			this.actorsStore.setOrganizationActors(orgId, actors);
			return actors;
		}

		return [];
	}

	public async uploadAvatar(actorId: Actor['id'], formData: FormData): Promise<Actor> {
		return this.actorsApiAdapter.uploadAvatar(actorId, formData);
	}

	public async removeAvatar(actorId: Actor['id']): Promise<Actor> {
		return this.actorsApiAdapter.removeAvatar(actorId);
	}

	public async update(actorId: Actor['id'], actorData: Partial<Actor>): Promise<Actor> {
		return this.actorsApiAdapter.update(actorId, actorData);
	}
}
