import { type App } from 'vue';
import { createI18n } from 'vue-i18n';
import { messages } from '@common/locales';

const i18n = createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages,
});

export function useI18n(app: App): void {
	app.use(i18n);
}

export default i18n;
