import { Alert, AlertType, UniqueAlert } from '@infrastructure/alerts/types/alerts.types';
import i18n from '@plugins/i18n.plugin';

const { t } = i18n.global;

export const WebsocketsConnectionErrorAlert: UniqueAlert = {
	id: 'websocket_disconnected',
	title: t('infrastructure.websockets.connectionErrorAlert.title'),
	message: t('infrastructure.websockets.connectionErrorAlert.message'),
	cta: t('infrastructure.websockets.connectionErrorAlert.cta'),
	ctaCallback: () => window.location.reload,
	type: AlertType.DANGER,
};

export const WebsocketsConnectionRestoredAlert: Alert = {
	title: t('infrastructure.websockets.connectionRestoredAlert.title'),
	message: t('infrastructure.websockets.connectionRestoredAlert.message'),
	type: AlertType.SUCESS,
	autoDismiss: true,
};
