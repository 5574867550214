import { ActorsServiceSymbol } from '@modules/actors/actors.module';
import { BoardsStoreSymbol } from '@modules/boards/boards.module';
import { CommitmentsServiceSymbol } from '@modules/commitments/commitments.module';
import { TimeframesServiceSymbol } from '@modules/timeframes/timeframes.module';
import { ActorsService } from '@modules/actors/services/actors.service';
import { Actor } from '@modules/actors/types/actors.types';
import { CommitmentsService } from '@modules/commitments/services/commitments.service';
import { CommitmentWithIncludes } from '@modules/commitments/types/commitments.types';
import { TimeframesService } from '@modules/timeframes/services/timeframes.service';
import { TimeframeData } from '@modules/timeframes/types/timeframes.types';
import { hasCommonElements } from '@utils/arrays';
import { ensureNumber } from '@utils/variables';
import { IBoardsStore } from '../stores/boards.store';
import { BoardWithIncludes } from '../types/boards.types';

export class BoardsCommitmentsService {
	private readonly actorsService;
	private readonly boardsStore;
	private readonly commitmentsService;
	private readonly timeframesService;

	constructor(dependencies: {
		[ActorsServiceSymbol]: ActorsService;
		[BoardsStoreSymbol]: IBoardsStore;
		[CommitmentsServiceSymbol]: CommitmentsService;
		[TimeframesServiceSymbol]: TimeframesService;
	}) {
		this.actorsService = dependencies[ActorsServiceSymbol];
		this.boardsStore = dependencies[BoardsStoreSymbol];
		this.commitmentsService = dependencies[CommitmentsServiceSymbol];
		this.timeframesService = dependencies[TimeframesServiceSymbol];
	}

	public addCommitmentToMap(board: BoardWithIncludes, commitment: CommitmentWithIncludes) {
		const commitmentActorIds = commitment.actors.map((actor) => ensureNumber(actor.id));
		const commitmentBoardId = ensureNumber(commitment.boardId);
		const boardId = ensureNumber(board.id);
		const boardActorsIds = board.actors.map((actor) => ensureNumber(actor.id));

		if (
			!this.canDisplayCommitmentOnBoard(
				commitmentActorIds,
				commitmentBoardId,
				boardId,
				boardActorsIds,
			)
		) {
			return;
		}

		const matchingTimeframe = this.findMatchingTimeframe(board, commitment);

		if (!matchingTimeframe) {
			return;
		}

		const actorsIds = commitmentActorIds.length > 0 ? commitmentActorIds : this.getUnassigned();

		for (const actorId of actorsIds) {
			const existingCommitmentsIds =
				this.boardsStore.boardMaps[board.id][matchingTimeframe.signature].actorsCommitments[
					actorId
				];

			const commitmentId = ensureNumber(commitment.id);

			if (existingCommitmentsIds && !existingCommitmentsIds.includes(commitmentId)) {
				const commitmentsIds = this.commitmentsService
					.filterCommitments({
						ids: [...existingCommitmentsIds, commitmentId],
						sorted: true,
					})
					.map((commitment) => ensureNumber(commitment.id));

				this.boardsStore.updateCommitmentsInMap(
					commitmentsIds,
					board.id,
					matchingTimeframe.signature,
					actorId,
				);
			}
		}
	}

	public removeCommitmentFromMap(board: BoardWithIncludes, commitment: CommitmentWithIncludes) {
		const actorIds = commitment.actors.map((actor) => actor.id);
		const matchingTimeframe = this.findMatchingTimeframe(board, commitment);

		if (!matchingTimeframe) {
			return;
		}

		const actorsIds = actorIds.length > 0 ? actorIds : this.getUnassigned();

		for (const actorId of actorsIds) {
			this.boardsStore.removeCommitmentFromMap(
				ensureNumber(commitment.id),
				board.id,
				matchingTimeframe.signature,
				actorId,
			);
		}
	}

	public updateCommitmentInMap(
		board: BoardWithIncludes,
		commitments: { new: CommitmentWithIncludes; old: CommitmentWithIncludes },
	) {
		if (!this.isCommitmentRemapRequiredAfterUpdate(commitments)) {
			return;
		}

		this.removeCommitmentFromMap(board, commitments.old);
		this.addCommitmentToMap(board, commitments.new);
	}

	public canDisplayCommitmentOnBoard(
		commitmentActorIds: Actor['id'][],
		commitmentBoardId: BoardWithIncludes['id'],
		boardId: BoardWithIncludes['id'],
		boardActorsIds: Actor['id'][],
	) {
		return (
			(commitmentActorIds.length === 0 && commitmentBoardId === boardId) ||
			hasCommonElements(commitmentActorIds, boardActorsIds)
		);
	}

	public isCommitmentRemapRequiredAfterUpdate(commitments: {
		new: CommitmentWithIncludes;
		old: CommitmentWithIncludes;
	}) {
		return (
			commitments.new.actors !== commitments.old.actors ||
			commitments.new.dueDate !== commitments.old.dueDate
		);
	}

	public findMatchingTimeframe(
		board: BoardWithIncludes,
		commitment: CommitmentWithIncludes,
	): TimeframeData | null {
		const timeframes = this.boardsStore.boardTimeframes[board.id];

		if (!timeframes) {
			return null;
		}

		const matchingTimeframe = this.timeframesService.findMatchingTimeframeForCommitment(
			commitment,
			timeframes,
		);

		return matchingTimeframe || null;
	}

	private getUnassigned(): Actor['id'][] {
		return [this.actorsService.getUnassigned().id];
	}
}
