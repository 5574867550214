import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { IHttpService } from '@infrastructure/http/services/http.service';
import { Organization } from '../types/organizations.types';
import { PendingInvitation, User } from '@modules/users/types/users.types';

export class OrganizationsInvitationsApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	public async checkPendingInvitations(userId: User['id']): Promise<PendingInvitation[]> {
		const response = this.httpService.get(`users/${userId}/invitations/pending`);
		return this.httpService.json<PendingInvitation[]>(response);
	}

	public async acceptInvitation(invitationId: PendingInvitation['id']): Promise<PendingInvitation> {
		const response = this.httpService.patch(`invitations/${invitationId}/accept`);
		return this.httpService.json<PendingInvitation>(response);
	}

	public async createInvitations(
		organizationId: Organization['id'],
		invitees: Array<string>,
	): Promise<void> {
		await this.httpService.post(`organizations/${organizationId}/invitations`, {
			json: { invitees },
		});
	}
}
