import { defineStore } from 'pinia';

import { type Actor } from '@modules/actors/types/actors.types';
import { type CommitmentWithIncludes } from '../types/commitments.types';
import { ensureNumber } from '@utils/variables';

export const COMMITMENTS_STORE_NAME = 'commitments';

export type CommitmentsObject = {
	[index: number | string]: CommitmentWithIncludes;
};
export type ICommitmentsStore = ReturnType<typeof useCommitmentsStore>;
export interface CommitmentsStoreFilterOptions {
	ids?: CommitmentWithIncludes['id'][];
	actorIds?: Actor['id'][];
	unassigned?: boolean;
	sorted?: boolean;
}

export const useCommitmentsStore = defineStore(COMMITMENTS_STORE_NAME, {
	state: () => ({
		commitments: {} as CommitmentsObject,
		areBeingEdited: [] as CommitmentWithIncludes['id'][],
	}),
	getters: {
		getCommitment: (state) => {
			return (commitmentId: CommitmentWithIncludes['id']): CommitmentWithIncludes =>
				state.commitments[ensureNumber(commitmentId)];
		},
		getCommitments: (state) => {
			return (filterOptions: CommitmentsStoreFilterOptions): CommitmentWithIncludes[] => {
				let commitments = filterOptions.ids
					? filterById(state.commitments, filterOptions.ids)
					: Object.values(state.commitments);

				if (filterOptions.unassigned) {
					commitments = filterUnassigned(commitments);
				} else if (filterOptions.actorIds) {
					commitments = filterByActorIds(commitments, filterOptions.actorIds);
				}

				if (filterOptions.sorted) {
					commitments = commitments.sort((a, b) => (a.rank > b.rank ? 1 : -1));
				}

				return commitments;
			};
		},
	},
	actions: {
		setCommitments(commitments: CommitmentWithIncludes[] | CommitmentsObject) {
			if (Array.isArray(commitments)) {
				const commitmentsObject: CommitmentsObject = commitments.reduce(
					(commitments, commitment) => {
						return Object.assign(commitments, { [commitment.id]: commitment });
					},
					{},
				);

				this.commitments = commitmentsObject;
			} else {
				this.commitments = commitments as CommitmentsObject;
			}
		},

		upsertCommitment(commitment: CommitmentWithIncludes) {
			if (this.commitments[commitment.id]) {
				Object.assign(this.commitments[commitment.id], commitment);
			} else {
				this.commitments[commitment.id] = commitment;
			}
		},

		removeCommitment(commitmentId: CommitmentWithIncludes['id']) {
			delete this.commitments[commitmentId];
		},
	},
});

function filterById(commitments: CommitmentsObject, ids: CommitmentWithIncludes['id'][]) {
	return ids.reduce((result, id) => {
		if (commitments[id]) {
			result.push(commitments[id]);
		}
		return result;
	}, [] as CommitmentWithIncludes[]);
}

function filterByActorIds(commitments: CommitmentWithIncludes[], actorIds: Actor['id'][]) {
	return commitments.filter(
		(commitment) =>
			commitment.actors && commitment.actors.some((actor) => actorIds.includes(actor.id)),
	);
}

function filterUnassigned(commitments: CommitmentWithIncludes[]) {
	return commitments.filter((commitment) => !commitment.actors || commitment.actors.length === 0);
}
