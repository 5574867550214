import {
	OrganizationsApiAdapterSymbol,
	OrganizationsStoreSymbol,
	OrganizationsWebsocketsServiceSymbol,
} from '@modules/organizations/organizations.module';
import { OrganizationsApiAdapter } from '../api/organizations.api';
import {
	CreateOrganizationRequest,
	Organization,
	OrganizationType,
	SlugValidationResponse,
} from '../types/organizations.types';
import { IOrganizationStore } from '../stores/organizations.store';
import { OrganizationsWebsocketsService } from './organizations-websockets.service';
import {
	CurrentOrganizationMissing,
	PersonalOrganizationNotFound,
} from '../errors/organizations.errors';

export class OrganizationsService {
	private readonly organizationsApiAdapter;
	private readonly organizationsStore;
	private readonly organizationsWebsocketService;

	constructor(dependencies: {
		[OrganizationsApiAdapterSymbol]: OrganizationsApiAdapter;
		[OrganizationsWebsocketsServiceSymbol]: OrganizationsWebsocketsService;
		[OrganizationsStoreSymbol]: IOrganizationStore;
	}) {
		this.organizationsStore = dependencies[OrganizationsStoreSymbol];
		this.organizationsApiAdapter = dependencies[OrganizationsApiAdapterSymbol];
		this.organizationsWebsocketService = dependencies[OrganizationsWebsocketsServiceSymbol];
	}

	async resolveOrganizationContext(
		orgSlugRouteParam: string | string[] | undefined,
		availableOrganizations: Organization[],
	): Promise<void> {
		let organization;
		const slug = orgSlugRouteParam ? String(orgSlugRouteParam) : undefined;

		if (slug) {
			organization = availableOrganizations.find((org) => org.slug === slug);

			if (organization) {
				this.organizationsWebsocketService.leaveAllOrganizationsRooms();
				this.organizationsWebsocketService.joinOrganizationWebsocketRoom(organization.id);
			}
		} else {
			organization = this.resolvePersonalOrganization(availableOrganizations);

			if (!organization) {
				throw new PersonalOrganizationNotFound();
			}

			this.organizationsWebsocketService.leaveAllOrganizationsRooms();
			for (const organization of availableOrganizations) {
				this.organizationsWebsocketService.joinOrganizationWebsocketRoom(organization.id);
			}
		}

		this.organizationsStore.setCurrentOrganization(organization);
	}

	getCurrentOrganizationOrFail(): Organization {
		const currentOrg = this.organizationsStore.currentOrganization;

		if (currentOrg) {
			return currentOrg;
		}

		throw new CurrentOrganizationMissing();
	}

	getCurrentOrganizationId(): Organization['id'] {
		return this.getCurrentOrganizationOrFail().id;
	}

	isCurrentOrganizationPersonal(): boolean {
		return (
			!!this.organizationsStore.currentOrganization &&
			this.organizationsStore.currentOrganization.type === OrganizationType.PERSONAL
		);
	}

	public async create(data: CreateOrganizationRequest) {
		return this.organizationsApiAdapter.post(data);
	}

	public async validateSlug(slug: string): Promise<SlugValidationResponse> {
		return this.organizationsApiAdapter.validateSlug({ slug });
	}

	public async createInvitations(
		organizationId: Organization['id'],
		invitees: Array<string>,
	): Promise<void> {
		await this.organizationsApiAdapter.createInvitations(organizationId, invitees);
	}

	private resolvePersonalOrganization(organizations: Organization[]) {
		return organizations.find((org) => org.type === OrganizationType.PERSONAL);
	}
}
