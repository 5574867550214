import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { UsersApiAdapter } from '@modules/users/api/users.api';
import { UsersService } from '@modules/users/services/users.service';
import { IUsersStore, useUsersStore } from '@modules/users/stores/users.store';

export const UsersApiAdapterSymbol: unique symbol = Symbol('Users API Adapter');
export const UsersServiceSymbol: unique symbol = Symbol('Users Service');
export const UsersStoreSymbol: unique symbol = Symbol('Users Store');

export const usersModule = {
	[UsersApiAdapterSymbol]: asClass(UsersApiAdapter),
	[UsersServiceSymbol]: asClass(UsersService),
	[UsersStoreSymbol]: asFunction(() => useUsersStore()).singleton(),
};

export function useUsersModule() {
	const container = useDependenciesContainer();
	return {
		usersService: container.resolve(UsersServiceSymbol) as UsersService,
		usersStore: container.resolve(UsersStoreSymbol) as IUsersStore,
	};
}
