import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { ActorsApiAdapter } from '@modules/actors/api/actors.api';
import { ActorsService } from '@modules/actors/services/actors.service';
import { useActorsStore } from '@modules/actors/stores/actors.store';

export const ActorsApiAdapterSymbol: unique symbol = Symbol('Actors API Adapter');
export const ActorsServiceSymbol: unique symbol = Symbol('Actors Service');
export const ActorsStoreSymbol: unique symbol = Symbol('Actors Store');

export const actorsModule = {
	[ActorsApiAdapterSymbol]: asClass(ActorsApiAdapter),
	[ActorsServiceSymbol]: asClass(ActorsService),
	[ActorsStoreSymbol]: asFunction(() => useActorsStore()).singleton(),
};

export function useActorsModule() {
	const container = useDependenciesContainer();
	return {
		actorsService: container.resolve(ActorsServiceSymbol) as ActorsService,
	};
}
