import { defineStore } from 'pinia';

import { type User } from '../types/users.types';

export const ACCOUNT_STORE_NAME = 'users';

export const useUsersStore = defineStore(ACCOUNT_STORE_NAME, {
	state: () => ({
		users: [] as User[],
		authUser: undefined as User | undefined,
	}),
	getters: {},
	actions: {
		setUsers(users: User[]): void {
			this.users = users;
		},
		setAuthUser(user: User): void {
			this.authUser = user;
		},
		resetAuthUser(): void {
			this.authUser = undefined;
		},
	},
});

export type IUsersStore = ReturnType<typeof useUsersStore>;
