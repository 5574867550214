import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

const AUTH_STORE_NAME = 'auth';
const EMPTY_ACCESS_TOKEN = '';

export type IAuthStore = ReturnType<typeof useAuthStore>;
export const useAuthStore = defineStore(AUTH_STORE_NAME, {
	state: () => ({
		accessToken: useStorage('accessToken', EMPTY_ACCESS_TOKEN),
	}),
	getters: {
		hasAccessToken: (state) => {
			return (): boolean => state.accessToken !== EMPTY_ACCESS_TOKEN;
		},
		getAccessToken: (state) => {
			return (): string => state.accessToken;
		},
	},
	actions: {
		setAccessToken(accessToken: string): void {
			this.accessToken = accessToken;
		},
		clearAccessToken(): void {
			this.accessToken = EMPTY_ACCESS_TOKEN;
		},
	},
});
