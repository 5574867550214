import { asClass, asFunction } from 'awilix';
import { AlertsService } from '@infrastructure/alerts/services/alerts.service';
import { useAlertsStore } from '@infrastructure/alerts/stores/alerts.store';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

export const AlertsServiceSymbol: unique symbol = Symbol('Alerts Service');
export const AlertsStoreSymbol: unique symbol = Symbol('Alerts Store');

export const alertsModule = {
	[AlertsServiceSymbol]: asClass(AlertsService),
	[AlertsStoreSymbol]: asFunction(() => useAlertsStore()).singleton(),
};

export function useAlertsModule() {
	const container = useDependenciesContainer();
	return { alertsService: container.resolve(AlertsServiceSymbol) as AlertsService };
}
