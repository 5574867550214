import { ApiError } from '@common/errors/ApiError';
import { CustomError } from '@common/errors/CustomError';

export class CommitmentsNotFoundApiError extends ApiError {
	constructor(id: number | string) {
		super(404, `Commitment with ID ${id} not found in the database.`);
	}
}

export class CommitmentInvalidDueDateError extends CustomError {
	constructor(dueDate: any) {
		super(`${dueDate} is not a valid commitment due date`);
	}
}
