import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { type IHttpService } from '@infrastructure/http/services/http.service';

import { Actor } from '@modules/actors/types/actors.types';
import { Organization } from '@modules/organizations/types/organizations.types';
import {
	CreateBoardFormData,
	type BoardWithIncludes,
	Board,
	UpdateBoardFormData,
} from '../types/boards.types';

export class BoardsApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	public async getAllBoards(orgId: number): Promise<BoardWithIncludes[]> {
		const response = this.httpService.get(`org/${orgId}/boards`);
		const boards = await this.httpService.json<BoardWithIncludes[]>(response);

		return boards;
	}

	public async getBoard(id: BoardWithIncludes['id']): Promise<BoardWithIncludes> {
		const response = this.httpService.get(`boards/${id}`);
		const board = await this.httpService.json<BoardWithIncludes>(response);

		return board;
	}

	public async getSharedBoardsForActor(
		orgId: Organization['id'],
		actorId: Actor['id'],
	): Promise<BoardWithIncludes[]> {
		const response = this.httpService.get(`org/${orgId}/boards`, {
			searchParams: { actorId },
		});
		const boards = await this.httpService.json<BoardWithIncludes[]>(response);

		return boards;
	}

	public async getPersonalBoardInOrganization(
		orgId: Organization['id'],
	): Promise<BoardWithIncludes> {
		const response = this.httpService.get(`org/${orgId}/boards/personal`);

		return await this.httpService.json<BoardWithIncludes>(response);
	}

	public async getPersonalBoardInOrgForActor(
		orgId: Organization['id'],
		actorId: Actor['id'],
	): Promise<BoardWithIncludes> {
		const response = this.httpService.get(`org/${orgId}/actor/${actorId}/boards/personal`);

		return await this.httpService.json<BoardWithIncludes>(response);
	}

	public async postBoard(
		orgId: Organization['id'],
		data: CreateBoardFormData,
	): Promise<BoardWithIncludes> {
		const response = this.httpService.post(`org/${orgId}/boards`, { json: data });

		return this.httpService.json<BoardWithIncludes>(response);
	}

	public async deleteBoard(boardId: Board['id']): Promise<void> {
		await this.httpService.delete(`boards/${boardId}`);
	}

	public async updateBoard(
		boardId: Board['id'],
		data: UpdateBoardFormData,
	): Promise<BoardWithIncludes> {
		const response = this.httpService.patch(`boards/${boardId}`, { json: data });

		return this.httpService.json<BoardWithIncludes>(response);
	}

	public async updateActors(
		boardId: BoardWithIncludes['id'],
		actorIds: Actor['id'][],
	): Promise<boolean> {
		const response = await this.httpService.put(`boards/${boardId}/actors`, {
			json: { actorIds },
		});

		return response.ok;
	}

	public async removeActors(boardId: BoardWithIncludes['id'], actorIds: Actor['id'][]) {
		const response = await this.httpService.delete(`boards/${boardId}/actors`, {
			json: { actorIds },
		});

		return response.ok;
	}
}
