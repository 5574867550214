import { computed, watch, onMounted } from 'vue';
import { useCommonStore } from '@common/store/common.store';

export function useThemes() {
	const selectedTheme = computed(() => useCommonStore().darkMode);

	onMounted(() => {
		setHtmlClasses(selectedTheme.value);
	});

	watch(selectedTheme, (newTheme, oldTheme) => {
		setHtmlClasses(newTheme);
	});

	function setHtmlClasses(isDarkMode: boolean) {
		const classes = isDarkMode ? 'h-full dark' : 'h-full';
		document.getElementsByTagName('html')[0].className = classes;
	}
}
