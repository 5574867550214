import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { type IHttpService } from '@infrastructure/http/services/http.service';

import { PendingInvitation, type User } from '../types/users.types';

export class UsersApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	public async getAuthUser(): Promise<User> {
		const response = this.httpService.get('auth_user');

		return this.httpService.json<User>(response);
	}

	public async checkPendingInvitations(): Promise<PendingInvitation[]> {
		const response = this.httpService.get('auth_user/pending_invitations');

		return this.httpService.json<PendingInvitation[]>(response);
	}

	public async acceptInvitation(invitationId: PendingInvitation['id']): Promise<void> {
		await this.httpService.patch(`auth_user/invitations/${invitationId}/accept`);
	}
}
