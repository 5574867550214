import { CustomError } from './CustomError';

export class GeneralError extends CustomError {
	constructor(message?: string) {
		super(message);
	}
}

export class MissingDependencyError extends CustomError {
	constructor(message?: string) {
		super(message);
	}
}
