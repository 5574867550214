import { BoardsApiAdapterSymbol } from '@modules/boards/boards.module';
import { BoardsApiAdapter } from '../api/boards.api';
import { Actor } from '@modules/actors/types/actors.types';
import { BoardWithIncludes } from '../types/boards.types';

export class BoardsActorsService {
	private boardsApiAdapter;

	constructor(dependencies: { [BoardsApiAdapterSymbol]: BoardsApiAdapter }) {
		this.boardsApiAdapter = dependencies[BoardsApiAdapterSymbol];
	}

	public async removeActors(
		boardId: BoardWithIncludes['id'],
		actorIds: Actor['id'][],
	): Promise<boolean> {
		const result = await this.boardsApiAdapter.removeActors(boardId, actorIds);

		return result;
	}

	public async updateActors(
		boardId: BoardWithIncludes['id'],
		actorIds: Actor['id'][],
	): Promise<boolean> {
		return this.boardsApiAdapter.updateActors(boardId, actorIds);
	}
}
