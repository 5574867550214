import { CustomError } from '@common/errors/CustomError';

export class EnvErrorMissingVariable extends CustomError {
	constructor(name: string) {
		super(`Missing env variable ${name}`);
	}
}

export class EnvErrorInvalidEnvironment extends CustomError {
	constructor() {
		super(`Config should have at least one environment (DEV, PROD, TEST) set to true.`);
	}
}
