import draggableComponent from 'vuedraggable/src/vuedraggable';
export const EVENT_UNAUTHENTICATED_REQUEST = 'unauthenticated_request';

export interface DraggableListHTMLElement extends HTMLElement {
	__draggable_component__: InstanceType<typeof draggableComponent>;
}

export interface DraggedHTMLElement extends HTMLElement {
	__draggable_context: {
		element: string;
		index: number;
	};
}

// Copied from: https://github.com/SortableJS/Sortable#event-object-demo
export interface DragAndDropEvent {
	to: DraggableListHTMLElement;
	from: DraggableListHTMLElement;
	item: DraggedHTMLElement;
	clone: HTMLElement;
	oldIndex: Number | undefined;
	newIndex: Number | undefined;
	oldDraggableIndex: Number | undefined;
	newDraggableIndex: Number | undefined;
	pullMode: String | Boolean | undefined;
}

// Copied from: https://github.com/SortableJS/Sortable#event-object-demo
export interface DragAndDropMoveEvent {
	to: DraggableListHTMLElement;
	from: DraggableListHTMLElement;
	dragged: DraggedHTMLElement;
	draggedRect: DOMRect;
	related: HTMLElement;
	relatedRect: DOMRect;
	willInsertAfter: Boolean;
}
