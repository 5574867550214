import {
	CommitmentsApiAdapterSymbol,
	CommitmentsStoreSymbol,
} from '@modules/commitments/commitments.module';
import { type CommitmentsFilterOptions } from '@modules/commitments/types/commitments.types';
import { type CommitmentsApiAdapter } from '../api/commitments.api';
import {
	CreateCommitmentDto,
	UpsertCommitmentRequestDto,
	type CommitmentWithIncludes,
} from '../types/commitments.types';
import { type TimeframeData } from '@modules/timeframes/types/timeframes.types';
import { CommitmentsStoreFilterOptions, ICommitmentsStore } from '../stores/commitments.store';
import {
	CommitmentCreatedEventKey,
	useCommitmentCreatedEvent,
} from '@modules/commitments/events/commitment-created.event';
import {
	CommitmentUpdatedEventKey,
	useCommitmentUpdatedEvent,
} from '@modules/commitments/events/commitment-updated.event';
import {
	CommitmentDeletedEventKey,
	useCommitmentDeletedEvent,
} from '@modules/commitments/events/commitment-deleted.event';

export type CommitmentsToTimeframesMap = {
	[index: TimeframeData['signature']]: {
		timeframeData: TimeframeData;
		commitments: CommitmentWithIncludes[];
	};
};

export class CommitmentsService {
	private commitmentsApiAdapter: CommitmentsApiAdapter;
	private commitmentsStore;

	constructor(dependencies: {
		[CommitmentsApiAdapterSymbol]: CommitmentsApiAdapter;
		[CommitmentsStoreSymbol]: ICommitmentsStore;
	}) {
		this.commitmentsApiAdapter = dependencies[CommitmentsApiAdapterSymbol];
		this.commitmentsStore = dependencies[CommitmentsStoreSymbol];
	}

	public filterCommitments(filters: CommitmentsStoreFilterOptions) {
		return this.commitmentsStore.getCommitments(filters);
	}

	async fetchCommitments(options: CommitmentsFilterOptions): Promise<CommitmentWithIncludes[]> {
		const commitments = await this.commitmentsApiAdapter.fetchCommitments(options);

		this.commitmentsStore.setCommitments(commitments);

		return commitments;
	}

	async createCommitment(data: CreateCommitmentDto): Promise<CommitmentWithIncludes | null> {
		const commitment = await this.commitmentsApiAdapter.createCommitment(data);

		if (commitment) {
			useCommitmentCreatedEvent.emit(CommitmentCreatedEventKey, { commitment });

			return this.addCommitmentToStore(commitment);
		}

		return null;
	}

	async deleteCommitment(commitmentId: CommitmentWithIncludes['id']): Promise<boolean> {
		const commitment = this.commitmentsStore.getCommitment(commitmentId);

		const result = await this.commitmentsApiAdapter.deleteCommitment(commitmentId);

		if (result) {
			useCommitmentDeletedEvent.emit(CommitmentDeletedEventKey, { commitment });

			this.commitmentsStore.removeCommitment(commitmentId);

			return true;
		}

		return false;
	}

	async updateCommitment(
		id: CommitmentWithIncludes['id'],
		data: UpsertCommitmentRequestDto,
	): Promise<CommitmentWithIncludes> {
		const oldCommitment = { ...this.commitmentsStore.getCommitment(id) };
		const newCommitment = await this.commitmentsApiAdapter.updateCommitment(id, data);

		useCommitmentUpdatedEvent.emit(CommitmentUpdatedEventKey, {
			commitments: { old: oldCommitment, new: newCommitment },
		});

		this.commitmentsStore.upsertCommitment(newCommitment);

		return newCommitment;
	}

	public addCommitmentToStore(commitment: CommitmentWithIncludes): CommitmentWithIncludes {
		this.commitmentsStore.upsertCommitment(commitment);
		return commitment;
	}

	public updateCommitmentInStore(commitment: CommitmentWithIncludes): CommitmentWithIncludes {
		this.commitmentsStore.upsertCommitment(commitment);
		return commitment;
	}

	public removeCommitmentFromStore(commitment: CommitmentWithIncludes): void {
		this.commitmentsStore.removeCommitment(commitment.id);
	}
}
