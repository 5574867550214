import { defineStore } from 'pinia';

import { Alert } from '../types/alerts.types';

export const ALERTS_STORE_NAME = 'alerts';

export type IAlertsStore = ReturnType<typeof useAlertsStore>;

export const useAlertsStore = defineStore(ALERTS_STORE_NAME, {
	state: () => ({
		alerts: [] as Alert[],
	}),
	getters: {
		hasUniqueAlert: (state) => {
			return (id: Alert['id']) => {
				return state.alerts.filter((alert) => alert.id && alert.id === id).length > 0;
			};
		},
		getAlerts: (state) => {
			return (limit: number = 0) => {
				return limit === 0 ? state.alerts : state.alerts.slice(0, limit - 1);
			};
		},
	},
	actions: {
		addAlerts(alerts: Alert[]): void {
			for (const alert of alerts) {
				this.alerts.unshift(alert);
			}
		},
		removeAlert(index: number): boolean {
			if (this.alerts[index]) {
				this.alerts.splice(index, 1);

				return true;
			}
			return false;
		},
		removeUniqueAlert(id: Alert['id']) {
			const index = this.alerts.findIndex((alert) => alert?.id === id);

			if (index > -1) {
				this.removeAlert(index);
			}
		},
	},
});
