import { asClass } from 'awilix';

import { WebsocketsService } from '@infrastructure/websockets/services/websockets.service';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

export const WebsocketsServiceSymbol: unique symbol = Symbol('Websockets Service');

export const websocketsModule = {
	[WebsocketsServiceSymbol]: asClass(WebsocketsService).singleton(),
};

export function useWebsocketsModule() {
	const container = useDependenciesContainer();
	return {
		websocketsService: container.resolve(WebsocketsServiceSymbol) as WebsocketsService,
	};
}
