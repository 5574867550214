import { defineStore } from 'pinia';

import { type Actor } from '../types/actors.types';
import { Organization } from '@modules/organizations/types/organizations.types';

export const ACTORS_STORE_NAME = 'actors';

export type IActorsStore = ReturnType<typeof useActorsStore>;
export const useActorsStore = defineStore(ACTORS_STORE_NAME, {
	state: () => ({
		actors: {} as {
			[index: Organization['id']]: {
				[index: Actor['id']]: {};
			};
		},
	}),
	getters: {
		getOrgActors: (state) => {
			return (orgId: Organization['id']) => state.actors[orgId];
		},
	},
	actions: {
		setOrganizationActors(orgId: Organization['id'], actors: Actor[]): void {
			this.actors[orgId] = actors.reduce(
				(object, actor) => {
					return { ...object, [actor.id]: actor };
				},
				{} as { [index: Actor['id']]: {} },
			);
		},
	},
});
