import { TimeframeDependecies } from '../types/timeframes.types';
import { TimeframeModelBase } from './Timeframe.model';
import { TimeframeData, DateTimeUnit } from '../types/timeframes.types';
import { SupportedTimezone } from '@infrastructure/datetime/types/datetime.types';
import { getLocalTimezone } from '@utils/datetime';

export class TimeframeM1 extends TimeframeModelBase {
	protected type: TimeframeData['type'];
	protected timeUnit: DateTimeUnit;

	constructor(dependencies: TimeframeDependecies) {
		super(dependencies);
		this.type = 'M1';
		this.timeUnit = 'month';
	}

	getTimeframeName(from: Date, to: Date, timezone: SupportedTimezone = getLocalTimezone()): string {
		return this.dateTimeService.toString(
			from,
			{
				month: 'short',
				year: 'numeric',
			},
			timezone,
		);
	}
}
