import { getTimeZones } from '@vvo/tzdb';

export function getLocalTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function areTimesEqualToMinutesPrecision(time1: number, time2: number) {
	const ONE_MINUTE_IN_MS = 60 * 1000;

	return Math.floor(time1 / ONE_MINUTE_IN_MS) === Math.floor(time2 / ONE_MINUTE_IN_MS);
}

export function getTimezoneDataByName(name: string) {
	return getTimeZones().filter((tz) => tz.name === name);
}

export function areTimezonesOffsetsEqual(tz1: string, tz2: string) {
	const tz1Data = getTimezoneDataByName(tz1)[0];
	const tz2Data = getTimezoneDataByName(tz2)[0];

	if (
		!tz1Data ||
		!tz2Data ||
		typeof tz1Data.currentTimeOffsetInMinutes !== 'number' ||
		typeof tz2Data.currentTimeOffsetInMinutes !== 'number'
	) {
		return false;
	}

	return tz1Data.currentTimeOffsetInMinutes === tz2Data.currentTimeOffsetInMinutes;
}
