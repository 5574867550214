import { useEventBus, type EventBusKey } from '@vueuse/core';
import { CommitmentWithIncludes } from '../types/commitments.types';

export const CommitmentUpdatedEventKey: EventBusKey<{ name: 'commitment_updated' }> =
	Symbol('commitment_updated');

export interface CommitmentUpdatedEventPayload {
	commitments: { old: CommitmentWithIncludes; new: CommitmentWithIncludes };
}

export const useCommitmentUpdatedEvent = useEventBus<
	typeof CommitmentUpdatedEventKey,
	CommitmentUpdatedEventPayload
>(CommitmentUpdatedEventKey);
