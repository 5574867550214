import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { type IHttpService } from '@infrastructure/http/services/http.service';
import { CustomHttpError } from '@infrastructure/http/errors/http.errors';
import {
	AuthEmailAlreadyTakenApiError,
	AuthInvalidCredentialsApiError,
	AuthInvalidPasswordApiError,
} from '../errors/auth.errors';

import {
	type CreateUserRequestDto,
	type CreateUserResponseDto,
	type LoginUserRequestDto,
	type LoginUserResponseDto,
} from '../types/auth.types';
import { User } from '@modules/users/types/users.types';

export class AuthApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	async login(data: LoginUserRequestDto): Promise<LoginUserResponseDto> {
		try {
			return await this.httpService.json(this.httpService.post('auth/login', { json: data }));
		} catch (err) {
			if (err instanceof CustomHttpError && [401, 404, 422].includes(err.response.status)) {
				throw new AuthInvalidCredentialsApiError();
			}
			throw err;
		}
	}

	async getUser(): Promise<User> {
		return this.httpService.json(this.httpService.get('auth/user'));
	}

	async createUser(data: CreateUserRequestDto): Promise<CreateUserResponseDto> {
		try {
			return await this.httpService.json(this.httpService.post('auth/signup', { json: data }));
		} catch (err) {
			if (err instanceof CustomHttpError) {
				if (err.response.status === 400) {
					throw new AuthInvalidPasswordApiError();
				} else if (err.response.status === 409) {
					throw new AuthEmailAlreadyTakenApiError();
				}
			}

			throw err;
		}
	}
}
