import { asClass, asFunction } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { AuthApiAdapter } from '@modules/auth/api/auth.api';
import { AuthService } from '@modules/auth/services/auth.service';
import { IAuthStore, useAuthStore } from '@modules/auth/stores/auth.store';

export const AuthServiceSymbol: unique symbol = Symbol('Auth Service');
export const AuthApiAdapterSymbol: unique symbol = Symbol('Auth API Adapter');
export const AuthStoreSymbol: unique symbol = Symbol('Auth Store');

export const authModule = {
	[AuthApiAdapterSymbol]: asClass(AuthApiAdapter),
	[AuthServiceSymbol]: asClass(AuthService),
	[AuthStoreSymbol]: asFunction(() => useAuthStore()).singleton(),
};

export function useAuthModule() {
	const container = useDependenciesContainer();
	return {
		authService: container.resolve(AuthServiceSymbol) as AuthService,
		authStore: container.resolve(AuthStoreSymbol) as IAuthStore,
	};
}
